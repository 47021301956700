import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Spinner from 'components/UI/spinner/Spinner';
import MainTitle from 'components/UI/title/Title';
import Avatar from 'components/UI/avatar/Avatar';
import Badge from 'components/UI/badge/Badge';
import Card from 'components/UI/card/Card';

import { useStore } from 'context';
import client from 'client';
import utils from 'utils';

import netlify from 'assets/images/logos/netlify.svg';
import firebase from 'assets/images/logos/firebase.svg';

import styling from './Activity.module.scss';

/**
 * An activity.
 * @param actor {object} user who triggered the activity
 * @param system {string} name of the system who triggered the activity
 * @param actionText {string} description of the activity
 * @param timestamp {string} timestamp of the activity
 * @returns {*}
 * @constructor
 */
const Activity = ({ actor, system, actionText, timestamp }) => {
    let avatar = <Avatar avatarId={actor?.avatar} />;
    let actorName = (actor?.firstName || 'Deleted') + ' ' + (actor?.lastName || 'User');
    let action = actionText;
    
    if (system === 'netlify') {
        avatar = (
            <div className={styling.netlifyLogo}>
                <img src={netlify} alt='netlify' />
            </div>
        );
        
        actorName = 'Netlify';
        
        if (actionText === 'error') {
            action = (
                <>
                    <span>failed to build</span>
                    <Badge size='tiny' type='danger'>error</Badge>
                </>
            );
        }
        
        if (actionText === 'ready') {
            action = (
                <>
                    <span>successfully created build</span>
                    <Badge size='tiny' type='success'>success</Badge>
                </>
            );
        }
    }
    
    if (system === 'firebase') {
        avatar = (
            <div className={styling.firebaseLogo}>
                <img src={firebase} alt='firebase' />
            </div>
        );
        
        actorName = 'Firebase';
        
        if (actionText === 'success') {
            action = (
                <>
                    <span>successfully deployed</span>
                    <Badge size='tiny' type='success'>success</Badge>
                </>
            );
        }
    }
    
    return (
        <>
            {avatar}
            
            <div>
                <div>{actorName}</div>
                <div className={styling.caption}>Actor</div>
            </div>
            
            <div>
                <div className={styling.action}>{action}</div>
                <div className={styling.caption}>Activity</div>
            </div>
            
            <div>
                <div>{utils.getDateWithTime(timestamp)}</div>
                <div className={styling.caption}>Date</div>
            </div>
        </>
    );
};


/**
 * Fetches all activities for the current site and renders them.
 * @returns {*}
 * @constructor
 */
const ActivityStream = () => {
    // Store
    const [, , setError] = useStore();
    
    // State
    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    // Params
    const { currentSite } = useParams();
    
    
    /**
     * Fetches the activity stream for the current site.
     * @type {(...args: any[]) => any}
     */
    const fetchActivityStream = useCallback(async () => {
        try {
            const activityStream = await client('getActivity', null, { siteId: currentSite });
            
            setActivities(activityStream?.activities || []);
            setIsLoading(false);
            
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setError(error);
        }
    }, [currentSite, setError]);
    
    
    /**
     * Fetches the activity stream if the fetchActivityStream
     * function changes.
     */
    useEffect(() => {
        fetchActivityStream();
    }, [fetchActivityStream]);
    
    
    return (
        <>
            <MainTitle title='Activity' subtitle='Activities on this site' />
            
            <Card>
                <div className={styling.spinner} hidden={!isLoading}>
                    <Spinner invert />
                </div>
                
                <ul hidden={isLoading}>
                    {activities.map(({ action, actor, timestamp, system }, i) => (
                        <li key={action + timestamp} className={styling.activity} style={{ animationDelay: i * 80 + 'ms' }}>
                            <Activity
                                actor={actor}
                                actionText={action}
                                system={system}
                                timestamp={timestamp}
                            />
                        </li>
                    ))}
                </ul>
            </Card>
        </>
    );
};

export default ActivityStream;