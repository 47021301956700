import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiImage } from 'react-icons/fi';

import { MediaItem } from 'components/mediaLibrary/MediaLibrary';
import InputField from 'components/UI/inputField/InputField';
import Spinner from 'components/UI/spinner/Spinner';
import Button from 'components/UI/button/Button';
import Badge from 'components/UI/badge/Badge';

import { useStore } from 'context';
import config from 'config';
import client from 'client';
import etalon from 'etalon';
import utils from 'utils';

import styling from '../../Builder.module.scss';
import copy from 'fast-copy';


/**
 * Name of the element.
 * @type {string}
 */
export const IMAGE_TYPE = 'image';


/**
 * Basic image model.
 * @type {{fileName: string, id: string, type: string}}
 */
export const imageModel = {
    type: IMAGE_TYPE,
    id: '',
    customId: '',
    headline: {
        [etalon.locales.en_US]: ''
    },
    imageName: '',
    externalImageUrl: ''
};


/**
 * Image block element shown in the post or page overview.
 * @param imageName {string} name of the selected image
 * @param externalImageUrl {string} url to an image that is not hosted on Caasy
 * @param status {string} status of the element
 * @returns {*}
 * @constructor
 */
export const ImageBlock = ({ imageName, externalImageUrl, status }) => (
    <div className={styling.blockWrapper}>
        <div className={styling.icon}>
            <FiImage hidden={imageName || externalImageUrl} />
            
            <img
                src={imageName ? config.connectivity.imageThumbnailBucket + '400x400_' + imageName : ''}
                alt='thumbnail'
                hidden={!imageName}
            />
            
            <img src={externalImageUrl ? externalImageUrl : ''} alt='thumbnail' hidden={!externalImageUrl} />
        </div>
        
        <div>
            <span className={styling.blockType}>IMAGE</span>
            
            <Badge size='tiny' type='neutral' hidden={!status}>{status}</Badge>
            
            <h6>{utils.removePrefix(imageName) || '-'}</h6>
        </div>
    </div>
);


/**
 * The icon is shown in the list of all elements.
 * @param selectHandler {function} must be invoked when the element is selected
 * @returns {*}
 * @constructor
 */
export const ImageIcon = ({ selectHandler }) => (
    <div className={styling.elementIcon} onClick={() => selectHandler(IMAGE_TYPE)}>
        <div>
            <FiImage />
        </div>
        
        <h6>Image</h6>
        
        <p>a simple image element</p>
    </div>
);


/**
 * The settings for the image element.
 * @param props {object} the component props
 * @returns {*}
 * @constructor
 */
export const ImageSettings = (props) => {
    // Store
    const [, , setError] = useStore();
    
    // State
    const [{ id, customId, imageName, externalImageUrl, headline }, setElementData] = useState(imageModel);
    const [isLoading, setIsLoading] = useState(true);
    const [availableImages, setAvailableImages] = useState([]);
    
    // Param
    const { currentSite } = useParams();
    
    
    /**
     * Handles input field changes
     * @param target {object} DOM node of the changed element
     */
    const changeHandler = ({ target }) => {
        const locale = target.getAttribute('data-caasy-locale') || '';
        
        setElementData(prevState => {
            prevState = copy(prevState);
            
            if (locale) {
                prevState[target.name][locale] = target.value;
            } else {
                prevState[target.name] = target.value;
            }
            
            return prevState;
        });
    };
    
    
    /**
     * Sets the file name of the selected image in the state.
     * @param imageName {string} the file name of the selected image
     */
    const selectHandler = (imageName) => {
        setElementData(prevState => ({ ...prevState, imageName }));
    };
    
    
    /**
     * Saves the edited settings.
     */
    const save = () => {
        const payload = { ...imageModel, id, customId, headline, imageName, externalImageUrl };
        props.saveSettings(payload);
    };
    
    
    /**
     * Fetches all media items and filters the images.
     * @returns {Promise<void>}
     */
    const fetchMedia = useCallback(async () => {
        try {
            const allMedia = await client('getAllMedia', null, { siteId: currentSite });
            
            setAvailableImages(allMedia.filter(x => x.type.includes('image')));
            setIsLoading(false);
            
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setError(error);
        }
    }, [currentSite, setError]);
    
    
    /**
     * Makes a local copy of the element's keys coming from props.
     */
    useEffect(() => {
        const { id, customId, headline, imageName, externalImageUrl } = props;
        setElementData(prevState => ({ ...prevState, id, customId, headline, imageName, externalImageUrl }));
    }, [props]);
    
    
    /**
     * Fetches all media items.
     */
    useEffect(() => {
        fetchMedia();
    }, [fetchMedia]);
    
    
    return (
        <>
            <div className={styling.header}>
                <h3>Image Settings</h3>
                <Badge size='tiny' type='info'>ID: {id}</Badge>
            </div>
            
            
            <div className={styling.row}>
                <div className={styling.column}>
                    <h6>Headline</h6>
                    <InputField
                        name='headline'
                        data-caasy-locale={etalon.locales.en_US}
                        placeholder='Headline'
                        value={headline[etalon.locales.en_US]}
                        onChange={changeHandler}
                        size='small'
                        test={config.regex.maxLength(etalon.maxLength.text)}
                    />
                </div>
            </div>
            
            
            <div className={styling.row}>
                <div className={styling.fullWidthColumn}>
                    <h6>Image</h6>
                    <div className={styling.mediaItems}>
                        <div className={styling.grid} hidden={isLoading}>
                            {availableImages.map(mediaItem => (
                                <MediaItem
                                    key={mediaItem.id}
                                    selectHandler={() => selectHandler(mediaItem.name)}
                                    mediaItem={mediaItem}
                                    isActive={mediaItem.name === imageName}
                                />
                            ))}
                        </div>
                        
                        <div className={styling.spinner} hidden={!isLoading}>
                            <Spinner invert />
                        </div>
                    </div>
                </div>
            </div>
            
            
            <div className={styling.row}>
                <div className={styling.fullWidthColumn}>
                    <h6>External Image Url</h6>
                    <InputField
                        name='externalImageUrl'
                        placeholder='External Image Url'
                        value={externalImageUrl}
                        onChange={changeHandler}
                        size='small'
                        test={config.regex.maxLength(etalon.maxLength.text)}
                    />
                </div>
            </div>
            
            
            <div hidden={props.isEditor}>
                <div className={styling.devZone} />
                <div className={styling.row}>
                    <div className={styling.column}>
                        <h6>Custom ID</h6>
                        <InputField
                            name='customId'
                            placeholder='Custom Element ID'
                            value={customId}
                            onChange={changeHandler}
                            size='small'
                        />
                    </div>
                </div>
            </div>
            
            
            <div className={styling.controls}>
                <Button size='smaller' color='light' onClick={props.closeSettings}>Cancel</Button>
                <Button size='smaller' onClick={save}>Confirm</Button>
            </div>
        </>
    );
};