import React, { useState } from 'react';
import { FiAlertTriangle, FiX } from 'react-icons/fi';

import styling from './InfoBox.module.scss';

const InfoBox = ({ type, children, title, closeableId }) => {
    const [isVisible, setIsVisible] = useState(true);
    
    /**
     * Hides the info box.
     */
    const closeHandler = () => {
        sessionStorage.setItem(closeableId, 'true');
        setIsVisible(false);
    };
    
    return (
        <div className={styling[type]} hidden={!isVisible || (closeableId && !!sessionStorage.getItem(closeableId))}>
            <div className={styling.icon}>
                <FiAlertTriangle />
                
                <div className={styling.close} onClick={closeHandler} hidden={!closeableId}>
                    <FiX />
                </div>
            </div>
            
            <h6>{title}</h6>
            <p>{children}</p>
        </div>
    );
};

export default InfoBox;