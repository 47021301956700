import React, { useState } from 'react';
import { FiCodepen } from 'react-icons/fi';

import { TextArea } from 'components/UI/inputField/InputField';
import InfoBox from 'components/UI/infoBox/InfoBox';
import Card, { Title } from 'components/UI/card/Card';
import MainTitle from 'components/UI/title/Title';
import Button from 'components/UI/button/Button';

import config from 'config';

import styling from './Feedback.module.scss';

const Feedback = () => {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [feedbackText, setFeedbackText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    /**
     * Submits the provided feedback.
     * @returns {Promise<void>}
     */
    const sendFeedback = async () => {
        try {
            setIsLoading(true);
            
            await fetch(config.connectivity.feedbackUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ message: feedbackText })
            });
            
            setShowSuccessMessage(true);
            setFeedbackText('');
            setIsLoading(false);
            
        } catch (error) {
            console.error(error);
        }
    };
    
    return (
        <>
            <MainTitle title='Feedback' subtitle='Let us know what you think' />
            
            <div className={styling.infoBox}>
                <InfoBox title='No support via feeback box' type='warning' closeableId='feedback'>
                    Please keep in mind that your feedback is anonymously. Therefore, we cannot provide any support
                    through this feedback box as we won't be able to find out who sent the message. Please also don't
                    send any sensitive information.
                </InfoBox>
            </div>
            
            <Card>
                <div className={styling.message} hidden={!showSuccessMessage}>Feedback sent. Thank you!</div>
                
                <Title icon={<FiCodepen />}>Anything missing?</Title>
                
                <p className={styling.caption}>
                    We are working hard to improve Caasy. As Caasy is still a young project, we would like to hear how
                    you like it. Tell us what you like and what we can do better.
                </p>
                
                <TextArea
                    placeholder='Enter your feedback'
                    value={feedbackText}
                    onChange={({ target }) => setFeedbackText(target.value)}
                    autoFocus
                />
                
                <Button
                    size='smaller'
                    onClick={sendFeedback}
                    disabled={!feedbackText.length}
                    isLoading={isLoading}
                >
                    Send
                </Button>
            </Card>
        </>
    );
};

export default Feedback;