import React, { useEffect, useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import InputField from 'components/UI/inputField/InputField';
import { WideButton } from 'components/UI/button/Button';
import Checkbox from 'components/UI/checkbox/Checkbox';
import Avatar from 'components/UI/avatar/Avatar';

import { useStore } from 'context';
import client from 'client';
import config from 'config';
import utils from 'utils';

import right from 'assets/images/persons/3.png';
import middle from 'assets/images/persons/2.png';
import left from 'assets/images/persons/1.png';

import caasy from 'assets/images/logos/caasy.svg';
import avatars from 'assets/images/avatars/';

import styling from './Authentication.module.scss';

const Authentication = ({ history, location }) => {
    // Store
    const { 1: dispatch } = useStore();

    console.log(avatars['1']);

    // State
    const initState = {
        mode: location.pathname.includes('signup') ? 'signUp' : 'signIn',
        loading: false,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordRepeat: '',
        selectedAvatar: '4',
        confirmed: false,
        error: ''
    };

    const [state, setState] = useState(initState);

    const {
        mode,
        loading,
        firstName,
        lastName,
        email,
        password,
        passwordRepeat,
        selectedAvatar,
        confirmed,
        error
    } = state;

    // Refs
    const avatarsScrollPosition = useRef(140);
    const avatarsContainer = useRef({});
    const content = useRef({});
    const banner = useRef({});


    /**
     * Handles input field changes.
     * @param name {string} name of the input field
     * @param value {string} text that was typed in into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };


    /**
     * Handles avatar changes.
     * @param avatarId {string} the ID of the avatar
     */
    const avatarHandler = (avatarId) => {
        setState(prevState => ({ ...prevState, selectedAvatar: avatarId }));
    };


    /**
     * Handles checkbox changes.
     * @param checked {boolean} the value of the checkbox
     */
    const checkBoxHandler = ({ target: { checked } }) => {
        setState(prevState => ({ ...prevState, confirmed: checked }));
    };


    /**
     * Prevents submitting the form if enter was clicked but the provided data is invalid.
     * @param e {object} event object
     */
    const enterHandler = (e) => {
        if (e.key === 'Enter' && !signInDataValid()) {
            e.preventDefault();
        }
    };


    /**
     * Scrolls the container of the avatars to the desired direction.
     * @param scrollChange {number} the numbers of pixels, positive or negative, the container should be scrolled from left
     */
    const scrollAvatars = (scrollChange) => {
        const maxScrollWidth = 2375 - avatarsContainer.current.getBoundingClientRect().width;

        avatarsScrollPosition.current += scrollChange;

        if (avatarsScrollPosition.current < 0) {
            avatarsScrollPosition.current = 0;
        }

        if (avatarsScrollPosition.current > maxScrollWidth) {
            avatarsScrollPosition.current = maxScrollWidth;
        }

        avatarsContainer.current.scrollTo({ left: avatarsScrollPosition.current, behavior: 'smooth' });
    };


    /**
     * Handles form submissions.
     * @param e {object} event object
     * @returns {Promise<void>}
     */
    const submit = async (e) => {
        try {
            e.persist();
            e.preventDefault();

            setState(prevState => ({ ...prevState, loading: true }));

            const payload = { firstName, lastName, email, password, avatar: selectedAvatar };

            const res = await client(mode, payload);

            dispatch({ type: 'update', payload: res });

            localStorage.setItem('access-pass', res.accessPass);
            localStorage.setItem('expiration-time', res.expirationTime);

            utils.expirationHandler(history, res.expirationTime);

            content.current.classList.add(styling.exit);
            banner.current.classList.add(styling.exitDelayed);

            document.title = 'Caasy | ' + res.email;

            const redirectUrl = history.location?.state?.redirectUrl || '/services';

            setTimeout(() => history.replace(redirectUrl), 1000);

        } catch (error) {
            setState(prevState => ({ ...prevState, loading: false, error: error.message }));
        }
    };

    /**
     * Validates the provided data for signing in.
     * @returns {boolean} if the provided data is valid
     */
    const signInDataValid = () => {
        return mode === 'signIn' && password.length > 4 && email.length > 4;
    };


    /**
     * Validates the provided data for signing up.
     * @returns {boolean|boolean} if the provided data is valid
     */
    const signUpDataValid = () => {
        const allDataProvided = email.length > 4 && password.length > 4 && passwordRepeat.length > 4;
        const passwordIsValid = config.regex.password.test(password);
        const passwordsMatch = password === passwordRepeat;

        return allDataProvided && passwordIsValid && passwordsMatch && confirmed;
    };


    /**
     * Updates the mode if the pathname changes.
     */
    useEffect(() => {
        const mode = location.pathname.includes('signup') ? 'signUp' : 'signIn';
        setState(prevState => ({ ...prevState, error: '', mode, email: '', password: '' }));

        if (avatarsContainer.current) {
            avatarsContainer.current.scrollLeft = avatarsScrollPosition.current;
        }
    }, [location.pathname]);


    // Load persisted credentials
    const accessPass = localStorage.getItem('access-pass');
    const expirationTime = localStorage.getItem('expiration-time');


    // Redirect if user is already logged in
    if (accessPass && expirationTime && new Date(parseInt(expirationTime)) > new Date()) {
        history.push('/sites');
    }

    // Escape invalid characters in password
    // eslint-disable-next-line
    const passwordMatchRegex = password.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');


    const signInComponent = (
        <form className={styling.content} onSubmit={submit}>
            <h1>Sign In</h1>
            <h4>Sign in with an existing account</h4>

            <p className={styling.error} hidden={!error}>{error}</p>

            <p className={styling.notification} hidden={!location.expired}>
                Your session has been expired. Sign in to continue.
            </p>

            <h6>Email</h6>
            <InputField
                key='si-email'
                name='email'
                value={email}
                onChange={changeHandler}
                onKeyPress={enterHandler}
                autoComplete='email'
                placeholder='Email'
                autoFocus
            />

            <h6>Password</h6>
            <InputField
                key='si-password'
                name='password'
                type='password'
                value={password}
                onChange={changeHandler}
                onKeyPress={enterHandler}
                autoComplete='current-password'
                placeholder='Password'
            />

            <WideButton type='submit' disabled={loading || !signInDataValid()}>
                <span>{loading ? 'Please wait' : 'Sign In'}</span>
            </WideButton>

            <div className={styling.resetPassword} hidden={mode === 'signUp'}>
                <Link to='/auth/resetpassword'>Forgot your password?</Link>
            </div>
        </form>
    );


    const signUpComponent = (
        <form className={styling.content} onSubmit={submit}>
            <h1>Sign Up</h1>
            <h4>Sign up a new account</h4>

            <p className={styling.error} hidden={!error}>{error}</p>

            <h6 key='su-avatar'>Choose an avatar</h6>
            <div key='su-avatar-selection' className={styling.avatarContainer}>
                <div className={styling.chevronLeft}>
                    <div className={styling.chevron} onClick={() => scrollAvatars(-180)}>
                        <FiChevronLeft />
                    </div>
                </div>

                <div className={styling.avatars} ref={avatarsContainer}>
                    {Object.keys(avatars).map(key => (
                        <Avatar
                            avatarId={key}
                            size='selectable'
                            key={key}
                            selected={key === selectedAvatar}
                            selectHandler={() => avatarHandler(key)}
                        />
                    ))}
                </div>

                <div className={styling.chevronRight} onClick={() => scrollAvatars(180)}>
                    <div className={styling.chevron}>
                        <FiChevronRight />
                    </div>
                </div>
            </div>

            <h6 key='su-firstName'>First Name</h6>
            <InputField
                key='su-firstName-input'
                name='firstName'
                autoComplete='off'
                value={firstName}
                onChange={changeHandler}
                placeholder='First name'
                autoFocus
            />

            <h6 key='su-lastName'>Last Name</h6>
            <InputField
                key='su-lastName-input'
                name='lastName'
                autoComplete='off'
                value={lastName}
                onChange={changeHandler}
                placeholder='Last name'
            />

            <h6 key='su-email'>Email</h6>
            <InputField
                key='su-email-input'
                name='email'
                autoComplete='email'
                value={email}
                onChange={changeHandler}
                placeholder='Email'
                test={config.regex.email}
            />

            <h6 key='su-password'>Password</h6>
            <InputField
                key='su-password-input'
                type='password'
                name='password'
                value={password}
                onChange={changeHandler}
                autoComplete='new-password'
                placeholder='Password'
                test={config.regex.password}
            />

            <span className={styling.passwordNotice}>
                8-15 characters with at least one number and special character
            </span>

            <h6 key='su-passwordRepeat'>Repeat Password</h6>
            <InputField
                key='su-passwordRepeat-input'
                name='passwordRepeat'
                type='password'
                value={passwordRepeat}
                onChange={changeHandler}
                autoComplete='new-password'
                placeholder='Repeat Password'
                test={new RegExp(passwordMatchRegex)}
            />

            <div className={styling.confirmBox}>
                <Checkbox checked={confirmed} id='confirmation' changeHandler={checkBoxHandler} />
                <span>
                    I confirm that I have read, understood and accept our <a href='https://caasy.io/privacy-policy' target='_blank' rel='noreferrer noopener'>Terms and Conditions</a> and <a href='https://caasy.io/terms-conditions' target='_blank' rel='noreferrer noopener'>Privacy Policy</a>.
                </span>
            </div>

            <WideButton type='submit' disabled={loading || !signUpDataValid()}>
                <span>{loading ? 'Please wait' : 'Sign Up'}</span>
            </WideButton>
        </form>
    );


    return (
        <div className={styling.authentication}>
            <main className={styling.container} ref={content}>
                <div className={styling.header}>
                    <img src={caasy} className={styling.logo} alt='caasy' />

                    <Link to={'/auth/' + (mode === 'signIn' ? 'signup' : 'signin')}>
                        {mode === 'signIn' ? 'Sign Up' : 'Sign In'}
                    </Link>
                </div>

                {mode === 'signIn' ? signInComponent : signUpComponent}
            </main>

            <div className={styling.banner} ref={banner}>
                <div>
                    <h1>Great to have you with us</h1>
                    <h4>You are almost ready to go. Thank you for building with Caasy.</h4>

                    <img src={left} className={styling.left} alt='person' />
                    <img src={middle} className={styling.middle} alt='person' />
                    <img src={right} className={styling.right} alt='person' />
                </div>
            </div>
        </div>
    );
};

export default Authentication;