import React from 'react';

import styling from './InputField.module.scss';

const InputField = (props) => {
    const updatedProps = {
        type: props.type ? props.type : 'text',
        'aria-label': props.name || '',
        ...props
    };
    
    delete updatedProps.test;
    
    let invalidStyle = '';
    
    if (props.test && props.value) {
        invalidStyle = !props.test.test(props.value) && styling.invalid;
    }
    
    return <input className={(styling[props.size] || styling.large) + ' ' + invalidStyle} {...updatedProps} />;
};

export const TextArea = (props) => {
    const updatedProps = {
        'aria-label': props.name || '',
        ...props
    };
    
    delete updatedProps.test;
    delete updatedProps.code;
    
    const baseStyle = props.code ? styling.textareaDark : styling.textarea;
    
    let invalidStyle = '';
    
    if (props.test && props.value) {
        invalidStyle = !props.test.test(props.value) && styling.invalid;
    }
    
    return <textarea className={baseStyle + ' ' + invalidStyle} {...updatedProps} />;
};

export default InputField;