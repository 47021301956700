import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EmailConfirmation from './components/auth/emailConfirmation/EmailConfirmation';
import Authentication from './components/auth/authentication/Authentication';
import PasswordReset from './components/auth/passwordReset/PasswordReset';
import UserManagement from './components/userManagement/UserManagement';
import SiteSettings from './components/siteSettings/SiteSettings';
import MediaLibrary from './components/mediaLibrary/MediaLibrary';
import Integrations from './components/integrations/Integrations';
import UserSettings from './components/userSettings/UserSettings';
import Blueprint from './components/layout/blueprint/Blueprint';
import Activity from './components/activity/Activity';
import Feedback from './components/feedback/Feedback';
import Toast from './components/layout/toast/Toast';
import Pages from './components/pages/Pages';
import Posts from './components/posts/Posts';
import Sites from 'components/sites/Sites';
import Page from './components/page/Page';
import Post from './components/post/Post';
import News from './components/news/News';

const App = () => (
    <>
        <Switch>
            <Route path='/auth/signin' component={Authentication} exact />
            <Route path='/auth/signup' component={Authentication} exact />
            <Route path='/auth/resetpassword' component={PasswordReset} exact />
            <Route path='/auth/newpassword' component={PasswordReset} exact />
            <Route path='/auth/confirmemail' component={EmailConfirmation} exact />
            
            <Blueprint>
                <Route path='/sites' component={Sites} exact />
                <Route path='/:currentSite/pages' component={Pages} />
                <Route path='/:currentSite/page/:pageId'component={Page} exact />
                <Route path='/:currentSite/page' component={Page} exact />
                <Route path='/:currentSite/posts' component={Posts} exact />
                <Route path='/:currentSite/post/:postId' component={Post} exact />
                <Route path='/:currentSite/post' component={Post} exact />
                <Route path='/:currentSite/media' component={MediaLibrary} />
                <Route path='/:currentSite/integrations' component={Integrations} exact />
                <Route path='/:currentSite/activity' component={Activity} exact />
                <Route path='/:currentSite/settings' component={SiteSettings} exact />
                <Route path='/:currentSite/news' component={News} exact />
                <Route path='/:currentSite/users' component={UserManagement} exact />
                <Route path='/account' component={UserSettings} exact />
                <Route path='/feedback' component={Feedback} exact />
            </Blueprint>
        </Switch>
        
        <Toast />
    </>
);

export default App;
