import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiMonitor } from 'react-icons/fi';
import ReactQuill from 'react-quill';
import copy from 'fast-copy';

import { MediaItem } from 'components/mediaLibrary/MediaLibrary';
import InputField from 'components/UI/inputField/InputField';
import Spinner from 'components/UI/spinner/Spinner';
import Button from 'components/UI/button/Button';
import Badge from 'components/UI/badge/Badge';

import { useStore } from 'context';
import etalon from 'etalon';
import client from 'client';
import config from 'config';

import styling from '../../Builder.module.scss';

/**
 * Name of the type.
 * @type {string}
 */
export const HERO_TYPE = 'hero';


/**
 * Basic hero model.
 * @type {{secondaryUrl: string, subtitle: {[p: string]: string}, id: string, label: {[p: string]: string}, text: {[p: string]: string}, type: string, customId: string, headline: {[p: string]: string}, mediaName: string, primaryUrl: string}}
 */
export const heroModel = {
    id: '',
    type: HERO_TYPE,
    customId: '',
    label: {
        [etalon.locales.en_US]: ''
    },
    headline: {
        [etalon.locales.en_US]: ''
    },
    subtitle: {
        [etalon.locales.en_US]: ''
    },
    text: {
        [etalon.locales.en_US]: ''
    },
    mediaName: '',
    primaryUrl: '',
    secondaryUrl: ''
};


/**
 * Hero element shown in the page or post overview.
 * @param headline {object} the headline in different languages
 * @param text {object} the text as rich text in different languages
 * @param status {string} status of the element
 * @returns {*}
 * @constructor
 */
export const HeroBlock = ({ headline, text, status }) => (
    <div className={styling.blockWrapper}>
        <div className={styling.icon}>
            <FiMonitor />
        </div>
        
        <div className={styling.blockDetails}>
            <span className={styling.blockType}>HERO</span>
            
            <Badge size='tiny' type='neutral' hidden={!status}>{status}</Badge>
            
            <h6>{headline[etalon.locales.en_US] || '-'}</h6>
            
            <p className={styling.blockCaption} dangerouslySetInnerHTML={{ __html: text[etalon.locales.en_US] || '' }} />
        </div>
    </div>
);


/**
 * The icon shown in the list of all elements.
 * @param selectHandler {function} must be invoked when the element is selected
 * @returns {*}
 * @constructor
 */
export const HeroIcon = ({ selectHandler }) => (
    <div className={styling.elementIcon} onClick={() => selectHandler(HERO_TYPE)}>
        <div>
            <FiMonitor />
        </div>
        
        <h6>Hero</h6>
        
        <p>Hero element</p>
    </div>
);


/**
 * Settings of the hero element.
 * @param props {object} component props
 * @returns {*}
 * @constructor
 */
export const HeroSettings = (props) => {
    // Store
    const [, , setError] = useStore();
    
    // State
    const [state, setState] = useState(heroModel);
    const [isLoading, setIsLoading] = useState(true);
    const [availableMediaItems, setAvailableMediaItems] = useState([]);
    
    const { id, customId, mediaName, headline, label, primaryUrl, secondaryUrl, subtitle, text } = state;
    
    // Params
    const { currentSite } = useParams();
    
    
    /**
     * Saves the element's settings.
     */
    const save = () => {
        const payload = {
            ...heroModel,
            id,
            customId,
            mediaName,
            headline,
            label,
            primaryUrl,
            secondaryUrl,
            subtitle,
            text
        };
        props.saveSettings(payload);
    };
    
    
    /**
     * Handles input field changes.
     * @param target {object} the DOM node that was changed
     */
    const changeHandler = ({ target }) => {
        const locale = target.getAttribute('data-caasy-locale');
        
        setState(prevState => {
            prevState = copy(prevState);
            
            if (locale) {
                prevState[target.name][locale] = target.value;
            } else {
                prevState[target.name] = target.value;
            }
            
            return prevState;
        });
    };
    
    
    /**
     * Handles rich text editor changes.
     * @param value {string} the rich text that was entered into the rich text editor.
     */
    const richTextHandler = (value) => {
        setState(prevState => {
            prevState = copy(prevState);
            prevState.text[etalon.locales.en_US] = value;
            
            return prevState;
        });
    };
    
    
    /**
     * Handles image selections.
     * @param mediaName {string} name of the selected image
     */
    const selectMediaHandler = (mediaName) => {
        setState(prevState => {
            prevState = copy(prevState);
            
            if (prevState.mediaName === mediaName) {
                prevState.mediaName = '';
            } else {
                prevState.mediaName = mediaName;
            }
            
            return prevState;
        });
    };
    
    
    /**
     * Fetches all available media items and filters all images and videos.
     * @type {(...args: any[]) => any}
     */
    const fetchMediaItems = useCallback(async () => {
        try {
            const allMedia = await client('getAllMedia', null, { siteId: currentSite });
            
            setAvailableMediaItems(allMedia.filter(x => x.type.includes('image') || x.type.includes('video')));
            setIsLoading(false);
            
        } catch (error) {
            console.error(error);
            setError(error);
            
        } finally {
            setIsLoading(false);
        }
    }, [currentSite, setError]);
    
    
    /**
     * Saved the props to tje local state and fetches all media items.
     */
    useEffect(() => {
        const { id, customId, headline, label, subtitle, text, mediaName, primaryUrl, secondaryUrl } = props;
        
        setState(prevState => ({
            ...prevState,
            id,
            customId,
            headline,
            label,
            subtitle,
            text,
            mediaName,
            primaryUrl,
            secondaryUrl
        }));
        
        fetchMediaItems();
    }, [props, fetchMediaItems]);
    
    
    return (
        <>
            <div className={styling.header}>
                <h3>Hero</h3>
                <Badge size='tiny' type='info'>ID: {id}</Badge>
            </div>
            
            <div className={styling.row}>
                <div className={styling.column}>
                    <h6>Hero</h6>
                    <InputField
                        name='headline'
                        data-caasy-locale={etalon.locales.en_US}
                        placeholder='Headline'
                        value={headline[etalon.locales.en_US]}
                        onChange={changeHandler}
                        size='small'
                        test={config.regex.maxLength(etalon.maxLength.text)}
                    />
                </div>
                
                <div className={styling.column}>
                    <h6>Subtitle</h6>
                    <InputField
                        name='subtitle'
                        data-caasy-locale={etalon.locales.en_US}
                        placeholder='Subtitle'
                        value={subtitle[etalon.locales.en_US]}
                        onChange={changeHandler}
                        size='small'
                        test={config.regex.maxLength(etalon.maxLength.text)}
                    />
                </div>
            </div>
            
            <div className={styling.row}>
                <div className={styling.singleColumn}>
                    <h6>Label</h6>
                    <InputField
                        name='label'
                        data-caasy-locale={etalon.locales.en_US}
                        placeholder='Label'
                        value={label[etalon.locales.en_US]}
                        onChange={changeHandler}
                        size='small'
                        test={config.regex.maxLength(etalon.maxLength.text)}
                    />
                </div>
            </div>
            
            <div className={styling.row}>
                <div className={styling.fullWidthColumn}>
                    <h6>Media</h6>
                    <div className={styling.mediaItems}>
                        <div className={styling.grid} hidden={isLoading}>
                            {availableMediaItems.map(mediaItem => (
                                <MediaItem
                                    key={mediaItem.id}
                                    mediaItem={mediaItem}
                                    isActive={mediaItem.name === mediaName}
                                    selectHandler={() => selectMediaHandler(mediaItem.name)}
                                />
                            ))}
                        </div>
                        
                        <div className={styling.spinner} hidden={!isLoading}>
                            <Spinner invert />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className={styling.row}>
                <div className={styling.fullWidthColumn}>
                    <h6>Text</h6>
                    <ReactQuill value={text[etalon.locales.en_US]} onChange={richTextHandler} />
                </div>
            </div>
            
            <div className={styling.row}>
                <div className={styling.column}>
                    <h6>Primary URL</h6>
                    <InputField
                        name='primaryUrl'
                        placeholder='Primary Url'
                        value={primaryUrl}
                        onChange={changeHandler}
                        size='small'
                        test={config.regex.maxLength(etalon.maxLength.text)}
                    />
                </div>
                
                <div className={styling.column}>
                    <h6>Secondary URL</h6>
                    <InputField
                        name='secondaryUrl'
                        placeholder='Secondary Url'
                        value={secondaryUrl}
                        onChange={changeHandler}
                        size='small'
                        test={config.regex.maxLength(etalon.maxLength.text)}
                    />
                </div>
            </div>
            
            <div hidden={props.isEditor}>
                <div className={styling.devZone} />
                <div className={styling.row}>
                    <div className={styling.column}>
                        <h6>Custom ID</h6>
                        <InputField
                            name='customId'
                            placeholder='Custom ID'
                            value={customId}
                            onChange={changeHandler}
                            size='small'
                            test={config.regex.maxLength(etalon.maxLength.id)}
                        />
                    </div>
                </div>
            </div>
            
            <div className={styling.controls}>
                <Button size='smaller' color='light' onClick={props.closeSettings}>Cancel</Button>
                <Button size='smaller' onClick={save}>Save</Button>
            </div>
        </>
    );
};