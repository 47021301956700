import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FiActivity, FiCodepen, FiEdit, FiFolder, FiList, FiLogOut, FiPlus, FiRss, FiSettings, FiTerminal, FiUsers } from 'react-icons/fi';

import { useStore } from 'context';

import styling from './Sidebar.module.scss';

const Sidebar = ({ history }) => {
    const [store, dispatch] = useStore();
    
    const topLevelPath = history.location.pathname.split('/')[1];
    const secondLevelPath = history.location.pathname.split('/')[2];
    
    const hasAdminRights = (store?.currentSite?.role === 'admin') || (store?.currentSite?.role === 'owner');
    
    /**
     * Signs out the user.
     */
    const signOut = () => {
        localStorage.removeItem('access-pass');
        localStorage.removeItem('expiration-time');
        
        history.push('/auth/signin');
        
        dispatch({ type: 'reset' });
    };
    
    /**
     * Navigates to the given path.
     * @param path {string} the desired destination
     */
    const navigate = (path) => {
        history.push('/' + store.currentSite.id + path);
    };
    
    /**
     * Determines the styling for a given menu item.
     * @param path {string} the path represented by the menu item
     * @returns {*} styling of the menu item
     */
    const getStyling = (path) => {
        return (
            topLevelPath === path ||
            secondLevelPath === path ||
            (path === 'posts' && secondLevelPath === 'post') ||
            (path === 'pages' && secondLevelPath === 'page')
        )
            ? styling.active
            : '';
    };
    
    /**
     * Determines the styling of the sites menu item.
     * @returns {*} the CSS class for the sites menu item
     */
    const getSiteStyling = () => {
        return (topLevelPath === 'sites') ? styling.siteActive : styling.site;
    };
    
    const siteAvatar = (store?.currentSite?.avatar)
        ? <img src={store?.currentSite?.avatar} alt='site avatar' />
        : store?.currentSite?.name?.slice(0, 2);
    
    return (
        <aside className={styling.sidebar}>
            <div className={styling.content}>
                <Link className={getSiteStyling()} to='/sites'>
                    <div className={styling.icon}>{siteAvatar || <FiPlus />}</div>
                    <div className={styling.caption} hidden={!store?.currentSite?.name}>
                        <div>{store?.currentSite?.name}</div>
                        <div>click to manage sites</div>
                    </div>
                    <div hidden={store?.currentSite?.name}>Manage Sites</div>
                </Link>
                
                
                <div className={styling.title} hidden={!store?.currentSite?.name}>SITE SETTINGS</div>
                
                
                <ul className={styling.menu} hidden={!store?.currentSite?.name}>
                    <li className={getStyling('posts')} onClick={() => navigate('/posts')}>
                        <FiEdit /> <span>Blog Posts</span>
                    </li>
                    
                    <li className={getStyling('pages')} onClick={() => navigate('/pages')}>
                        <FiList /> <span>Pages</span>
                    </li>
                    
                    <li className={getStyling('media')} onClick={() => navigate('/media')}>
                        <FiFolder /> <span>Media Library</span>
                    </li>
                    
                    <li className={getStyling('news')} onClick={() => navigate('/news')}>
                        <FiRss /> <span>News</span>
                    </li>
                    
                    <li className={getStyling('users')} onClick={() => navigate('/users')}>
                        <FiUsers /> <span>User Management</span>
                    </li>
                    
                    <li className={getStyling('activity')} onClick={() => navigate('/activity')}>
                        <FiActivity /> <span>Activity</span>
                    </li>
                    
                    <li className={getStyling('integrations')} onClick={() => navigate('/integrations')}
                        hidden={!hasAdminRights}>
                        <FiTerminal /> <span>Integrations</span>
                    </li>
                    
                    <li className={getStyling('settings')} onClick={() => navigate('/settings')}
                        hidden={!hasAdminRights}>
                        <FiSettings /> <span>Site Settings</span>
                    </li>
                </ul>
                
                
                <div className={styling.title}>GENERAL</div>
                
                
                <ul className={styling.menu}>
                    <li className={getStyling('feedback')} onClick={() => history.push('/feedback')}>
                        <FiCodepen /> <span>Feedback</span>
                    </li>
                    
                    <li className={getStyling('account')} onClick={() => history.replace('/account')}>
                        <FiSettings /> <span>Account Settings</span>
                    </li>
                    
                    <li className={getStyling('logout')} onClick={signOut}>
                        <FiLogOut /> <span>Logout</span>
                    </li>
                </ul>
            </div>
        </aside>
    );
};

export default withRouter(Sidebar);