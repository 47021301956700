import React, { useState } from 'react';

import InputField from 'components/UI/inputField/InputField';
import DangerZone from 'components/UI/dangerZone/DangerZone';
import MainTitle from 'components/UI/title/Title';
import Avatar from 'components/UI/avatar/Avatar';
import Button from 'components/UI/button/Button';
import Badge from 'components/UI/badge/Badge';
import Card from 'components/UI/card/Card';

import { useStore } from 'context';
import client from 'client';
import config from 'config';

import styling from './UserSettings.module.scss';

const UserSettings = ({ history }) => {
    // Store
    const [{ sites, firstName, lastName, email, avatar, currentPlan, receivedRequests }, dispatch, setError] = useStore();
    
    // State
    const [isResetting, setIsResetting] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    
    
    /**
     * Handles input field changes.
     * @param name {string} name of the input field
     * @param value {string} value that was typed into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setHasChanged(true);
        dispatch({ type: 'update', payload: { [name]: value } });
    };
    
    
    /**
     * Updates the changed user data
     * @returns {Promise<void>}
     */
    const updateData = async () => {
        try {
            setIsUpdating(true);
            
            await client('updateUser', { firstName, lastName, email });
            
            setTimeout(() => {
                setIsUpdating(false);
                setHasChanged(false);
            }, 50);
            
        } catch (error) {
            console.error(error);
            setIsUpdating(false);
            setError(error);
        }
    };
    
    
    /**
     * Requests a password reset for the current user.
     * @returns {Promise<void>}
     */
    const resetPassword = async () => {
        try {
            setIsResetting(true);
            
            await client('resetPassword', { email });
            
            setTimeout(() => setIsResetting(false), 50);
            
        } catch (error) {
            console.error(error);
            setIsResetting(false);
            setError(error);
        }
    };
    
    
    /**
     * Deletes a user's account.
     * @returns {Promise<void>}
     */
    const deleteAccount = async () => {
        try {
            setIsDeleting(true);
            await client('deleteUser');
            
            localStorage.clear();
            sessionStorage.clear();
            
            history.push('/auth/signin');
            
        } catch (error) {
            console.error(error);
            setError(error);
            setIsDeleting(false);
        }
    };
    
    // Determine the timestamp of the first day of the current month
    const currentDate = new Date();
    
    currentDate.setUTCDate(1);
    currentDate.setUTCHours(0);
    currentDate.setUTCMinutes(0);
    currentDate.setUTCSeconds(0);
    
    const currentMonth = Math.floor(currentDate.getTime() / 1000);
    
    // Determine the amount of requests in the current month
    const requestCount = receivedRequests?.[currentMonth] || 0;
    
    // Determines if all fields are valid
    const isValid = firstName && lastName && email && config.regex.email.test(email);
    
    // Determines if user account can be deleted
    const ownsSite = sites && sites.some(x => x.role === 'owner');
    
    return (
        <>
            <MainTitle title='Settings' subtitle='Manage your personal settings' />
            
            <Card>
                <div className={styling.header}>
                    <Avatar avatarId={avatar} size='large' />
                    
                    <div className={styling.name}>
                        <h2>{firstName} {lastName}</h2>
                        <h4>{email}</h4>
                        <Badge type='info'>{(currentPlan || '').toUpperCase()}</Badge>
                    </div>
                </div>
                
                <div className={styling.row}>
                    <div>
                        <h6>First Name</h6>
                        <InputField
                            name='firstName'
                            value={firstName}
                            placeholder='First Name'
                            onChange={changeHandler}
                        />
                    </div>
                    
                    <div>
                        <h6>Last Name</h6>
                        <InputField
                            name='lastName'
                            value={lastName}
                            placeholder='Last Name'
                            onChange={changeHandler}
                        />
                    </div>
                </div>
                
                <div className={styling.column}>
                    <div>
                        <h6>Email Address</h6>
                        <InputField
                            name='email'
                            type='email'
                            value={email}
                            placeholder='Email Address'
                            onChange={changeHandler}
                            test={config.regex.email}
                        />
                    </div>
                </div>
                
                
                <div className={styling.controls}>
                    <Button
                        size='smaller'
                        isLoading={isUpdating}
                        onClick={updateData}
                        disabled={!isValid || !hasChanged}
                    >
                        Update
                    </Button>
                </div>
                
                
                <DangerZone />
                
                
                <div className={styling.flex}>
                    <div>
                        <h6>API Request Count</h6>
                        <p>This month we have processed {requestCount} API requests that regard data you own</p>
                    </div>
                    
                    <Button size='smaller' color='dangerLight' disabled={true}>Upgrade</Button>
                </div>
                
                
                <div className={styling.flex}>
                    <div>
                        <h6>Change Password</h6>
                        <p>You will receive an email with instructions to set a new password</p>
                    </div>
                    
                    <Button
                        size='smaller'
                        color='dangerLight'
                        onClick={resetPassword}
                        isLoading={isResetting}
                    >
                        Change
                    </Button>
                </div>
                
                
                <div className={styling.flex}>
                    <div>
                        <h6>Delete my account</h6>
                        <p hidden={!ownsSite}>You need to delete all the sites you own, before you can delete your account</p>
                        <p hidden={ownsSite}>Delete your account and all its data. This is permanent and cannot be undone</p>
                    </div>
                    
                    <Button
                        size='smaller'
                        color='dangerLight'
                        onClick={deleteAccount}
                        isLoading={isDeleting}
                        disabled={ownsSite}
                    >
                        Delete
                    </Button>
                </div>
            </Card>
        </>
    );
};

export default UserSettings;