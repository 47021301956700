// Dev buckets
const imageBucketDev = 'https://storage.googleapis.com/caasy-media-dev/';
const imageBucketThumbnailDev = 'https://storage.googleapis.com/caasy-media-thumbnails-dev/';

// Prod buckets
const imageBucket = 'https://storage.googleapis.com/caasy-media/';
const imageBucketThumbnail = 'https://storage.googleapis.com/caasy-media-thumbnails/';

// Environment
const isProduction = window.location.hostname === 'app.caasy.io';

export default {
    backendURL: isProduction ? 'https://api.caasy.io' : 'http://localhost:2800',
    feedbackURL: '',
    imageBucket: isProduction ? imageBucket : imageBucketDev,
    imageThumbnailBucket: isProduction ? imageBucketThumbnail : imageBucketThumbnailDev,
    feedbackUrl: 'https://europe-west1-caasy-282415.cloudfunctions.net/feedback'
}