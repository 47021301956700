import React, { useEffect, useState } from 'react';
import { GoTextSize } from 'react-icons/go';
import ReactQuill from 'react-quill';
import copy from 'fast-copy';

import InputField from 'components/UI/inputField/InputField';
import Button from 'components/UI/button/Button';
import Badge from 'components/UI/badge/Badge';

import etalon from 'etalon';
import config from 'config';

import styling from '../../Builder.module.scss';


/**
 * Name of the type.
 * @type {string}
 */
export const RICH_TEXT_TYPE = 'richText';


/**
 * Basic rich text model.
 * @type {{id: string, text: {[p: string]: string}, type: string, customId: string, headline: {[p: string]: string}}}
 */
export const richTextModel = {
    id: '',
    type: RICH_TEXT_TYPE,
    customId: '',
    headline: {
        [etalon.locales.en_US]: ''
    },
    text: {
        [etalon.locales.en_US]: ''
    }
};


/**
 * Rich text element shown in the post or page overview.
 * @param text {string} the HTML rich text
 * @param headline {string} the headline in different languages
 * @param status {string} the status of the element
 * @returns {*}
 * @constructor
 */
export const RichTextBlock = ({ text, headline, status }) => (
    <div className={styling.blockWrapper}>
        <div className={styling.icon}>
            <GoTextSize />
        </div>
        
        <div className={styling.blockDetails}>
            <span className={styling.blockType}>RICHTEXT</span>
            
            <Badge size='tiny' type='neutral' hidden={!status}>{status}</Badge>
            
            <h6>{headline[etalon.locales.en_US] || '-'}</h6>
            
            <p className={styling.blockCaption} dangerouslySetInnerHTML={{ __html: text[etalon.locales.en_US] || '' }} />
        </div>
    </div>
);

/**
 * The icon is shown in the list of all elements.
 * @param selectHandler {function} must be invoked when the element is selected
 * @returns {*}
 * @constructor
 */
export const RichTextIcon = ({ selectHandler }) => (
    <div className={styling.elementIcon} onClick={() => selectHandler(RICH_TEXT_TYPE)}>
        <div>
            <GoTextSize />
        </div>
        
        <h6>Rich Text</h6>
        
        <p>formatted rich text element</p>
    </div>
);


/**
 * Settings for the text element.
 * @param props
 * @returns {*}
 * @constructor
 */
export const RichTextSettings = (props) => {
    const [{ id, customId, headline, text }, setState] = useState(richTextModel);
    
    
    /**
     * Saves the settings.
     */
    const save = () => {
        const payload = { ...richTextModel, text, headline, id, customId };
        props.saveSettings(payload);
    };
    
    
    /**
     * Handles input field changes.
     * @param target {object} DOM node of the changed element
     */
    const changeHandler = ({ target }) => {
        const locale = target.getAttribute('data-caasy-locale') || '';
        
        setState(prevState => {
            prevState = copy(prevState);
            
            if (locale) {
                prevState[target.name][locale] = target.value;
            } else {
                prevState[target.name] = target.value;
            }
            
            return prevState;
        });
    };
    
    
    /**
     * Handles rich text input field changes.
     * @param value {string} the rich text
     */
    const richTextChangeHandler = (value) => {
        setState(prevState => {
            prevState = copy(prevState);
            prevState.text[etalon.locales.en_US] = value;
            
            return prevState;
        });
    };
    
    
    /**
     * Sets the provided data to the component's state.
     */
    useEffect(() => {
        const { id, customId, text, headline } = props;
        
        setState(prevState => ({ ...prevState, id, customId, headline, text }));
    }, [props]);
    
    
    return (
        <>
            <div className={styling.header}>
                <h3>Rich Text</h3>
                <Badge size='tiny' type='info'>ID: {id}</Badge>
            </div>
            
            <div className={styling.row}>
                <div className={styling.column}>
                    <h6>Headline</h6>
                    <InputField
                        name='headline'
                        data-caasy-locale={etalon.locales.en_US}
                        placeholder='Headline'
                        value={headline[etalon.locales.en_US]}
                        onChange={changeHandler}
                        size='small'
                        test={config.regex.maxLength(etalon.maxLength.text)}
                    />
                </div>
            </div>
            
            <div className={styling.row}>
                <div className={styling.fullWidthColumn}>
                    <h6>Rich Text</h6>
                    <ReactQuill value={text[etalon.locales.en_US]} onChange={richTextChangeHandler} />
                </div>
            </div>
            
            
            <div hidden={props.isEditor}>
                <div className={styling.devZone} />
                <div className={styling.row}>
                    <div className={styling.column}>
                        <h6>Custom ID</h6>
                        <InputField
                            name='customId'
                            placeholder='Custom ID'
                            value={customId}
                            onChange={changeHandler}
                            size='small'
                            test={config.regex.maxLength(etalon.maxLength.id)}
                        />
                    </div>
                </div>
            </div>
            
            
            <div className={styling.controls}>
                <Button size='smaller' color='light' onClick={props.closeSettings}>Cancel</Button>
                <Button size='smaller' onClick={save}>Save</Button>
            </div>
        </>
    );
};