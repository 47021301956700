import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Confirmation from 'components/UI/confirmation/Confirmation';
import { LoadingSpinner } from 'components/UI/spinner/Spinner';
import Content from 'components/UI/content/Content';
import Button from 'components/UI/button/Button';
import Title from 'components/UI/title/Title';

import { useStore } from 'context';
import client from 'client';
import etalon from 'etalon';

import styling from './Posts.module.scss';

/**
 * Renders all posts
 * @returns {*}
 * @constructor
 */
const Posts = ({ history }) => {
    // Store
    const [, , setError] = useStore();
    
    // State
    const [{ page, posts, postIdToDelete, fetchedAll, initialFetch, isFetching, isLoading }, setState] = useState({
        page: 1,
        posts: [],
        postIdToDelete: '',
        initialFetch: false,
        fetchedAll: false,
        isFetching: true,
        isLoading: false
    });
    
    // Params
    const { currentSite } = useParams();
    
    
    /**
     * Loads all blog posts.
     */
    const loadPosts = useCallback(async () => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));
            
            const opts = { siteId: currentSite, page };
            const posts = await client('getAllPosts', null, opts);
            
            setState(prevState => ({
                ...prevState,
                posts: [...prevState.posts, ...posts?.data || []],
                isFetching: false,
                page: prevState.page + 1,
                fetchedAll: posts?.data?.length < 25 || false,
                initialFetch: true,
                isLoading: false
            }));
            
        } catch (error) {
            console.error(error);
            setError(error);
            setState(prevState => ({ ...prevState, initialFetch: true, isFetching: false, isLoading: false }));
        }
    }, [currentSite, page, setError]);
    
    
    /**
     * Opens the post builder.
     */
    const newPost = () => {
        history.push('/' + currentSite + '/post/');
    };
    
    
    /**
     * Stages a post that should be deleted.
     * This will open the confirmation modal.
     * @param id {string} ID of the post that should be deleted
     */
    const toggleConfirmation = (id = '') => {
        if (typeof id !== 'string') {
            id = '';
        }
        setState(prevState => ({ ...prevState, postIdToDelete: id || '' }));
    };
    
    
    /**
     * Deletes a post with the staged post ID.
     */
    const deletePost = () => {
        try {
            client('deletePost', null, { postId: postIdToDelete, siteId: currentSite });
            
            const tmpPosts = [...posts].filter(x => x.id !== postIdToDelete);
            
            setState(prevState => ({ ...prevState, posts: tmpPosts, postIdToDelete: '' }));
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };
    
    
    /**
     * Fetches all blog posts when the URL changes.
     */
    useEffect(() => {
        if (!initialFetch) {
            loadPosts();
        }
    }, [loadPosts, initialFetch]);
    
    
    return (
        <>
            <Title title='Blog Posts' subtitle='Manage your blog posts' />
            
            <LoadingSpinner hidden={!isFetching} />
            
            <section className={styling.wrapper} hidden={isFetching}>
                <div className={styling.controls}>
                    <Button size='smaller' onClick={newPost}>New Post</Button>
                </div>
                
                <ul>
                    {posts.map((post, i) => (
                        <Content
                            key={post.id}
                            id={post.id}
                            index={i}
                            title={post?.title?.[etalon.locales.en_US] || 'n/a'}
                            author={post?.author}
                            updatedBy={post?.updatedBy}
                            avatarId={post?.author?.avatar}
                            lastUpdate={post.updatedAt}
                            isPublished={post.isPublished}
                            type='post'
                            deleteHandler={() => toggleConfirmation(post.id)}
                        />
                    ))}
                </ul>
                
                <div className={styling.loadMore}>
                    <Button size='smaller' hidden={fetchedAll} onClick={loadPosts} isLoading={isLoading}>
                        Load more
                    </Button>
                </div>
            </section>
            
            <Confirmation
                open={!!postIdToDelete}
                title='Delete Post'
                message='Please confirm that you want to delete this post and its data. This is permanent and cannot be undone.'
                cancelHandler={toggleConfirmation}
                confirmHandler={deletePost}
            />
        </>
    );
};

export default Posts;