export default {
    '1': require('assets/images/avatars/1.png').default,
    '2': require('assets/images/avatars/2.png').default,
    '3': require('assets/images/avatars/3.png').default,
    '4': require('assets/images/avatars/4.png').default,
    '5': require('assets/images/avatars/6.png').default,
    '6': require('assets/images/avatars/5.png').default,
    '7': require('assets/images/avatars/7.png').default,
    '8': require('assets/images/avatars/8.png').default,
    '9': require('assets/images/avatars/9.png').default,
    '10': require('assets/images/avatars/10.png').default,
    '11': require('assets/images/avatars/11.png').default,
    '12': require('assets/images/avatars/12.png').default,
    '13': require('assets/images/avatars/13.png').default,
    '14': require('assets/images/avatars/14.png').default,
    '15': require('assets/images/avatars/15.png').default,
    '16': require('assets/images/avatars/16.png').default,
    '17': require('assets/images/avatars/17.png').default,
    '18': require('assets/images/avatars/18.png').default,
    '19': require('assets/images/avatars/19.png').default,
    '20': require('assets/images/avatars/20.png').default,
    '21': require('assets/images/avatars/21.png').default,
    '22': require('assets/images/avatars/22.png').default,
    '23': require('assets/images/avatars/23.png').default,
    '24': require('assets/images/avatars/24.png').default,
    '25': require('assets/images/avatars/25.png').default
};