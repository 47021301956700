import React from 'react';

import Avatar from 'components/UI/avatar/Avatar';

import { useStore } from 'context';

import caasy from 'assets/images/logos/caasy.svg';

import styling from './Nav.module.scss';

const Nav = () => {
    const [store] = useStore();
    
    return (
        <nav className={styling.nav}>
            <div className={styling.wrapper}>
                <div className={styling.logo}>
                    <img src={caasy} alt='caasy' />
                </div>
                
                <a className={styling.link} href='https://caasy.io' target='_blank' rel='noreferrer noopener'>About</a>
                <a className={styling.link} href='https://docs.caasy.io' target='_blank' rel='noreferrer noopener'>Docs</a>
            </div>
            
            
            <div className={styling.user}>
                <div className={styling.details}>
                    <div className={styling.name}>{store.firstName} {store.lastName}</div>
                    <div className={styling.email}>{store.email}</div>
                </div>
                
                <Avatar avatarId={store.avatar} />
            </div>
        </nav>
    );
};

export default Nav;