import React, { useEffect, useRef, useState } from 'react';
import { FaYoutube } from 'react-icons/fa';
import debounce from 'lodash/debounce';

import InputField from 'components/UI/inputField/InputField';
import Button from 'components/UI/button/Button';
import Badge from 'components/UI/badge/Badge';

import styling from '../../Builder.module.scss';

/**
 * Name of type.
 * @type {string}
 */
export const YOUTUBE_TYPE = 'youtube';

/**
 * Basic YouTube model.
 * @type {{id: string, type: string, url: string}}
 */
export const youtubeModel = {
    id: '',
    customId: '',
    type: YOUTUBE_TYPE,
    videoId: ''
};

/**
 * YouTube element shown in the post or page overview.
 * @param status {string} status of the element
 * @param videoId {string} YouTube video ID
 * @returns {*}
 * @constructor
 */
export const YouTubeBlock = ({ status, videoId }) => (
    <div className={styling.blockWrapper}>
        <div className={styling.icon}>
            <FaYoutube hidden={videoId} />
            <img src={videoId ? `https://img.youtube.com/vi/${videoId}/default.jpg` : ''} alt='thumbnail' hidden={!videoId} />
        </div>
        
        <div className={styling.blockDetails}>
            <span className={styling.blockType}>YOUTUBE</span>
            
            <Badge size='tiny' type='neutral' hidden={!status}>{status}</Badge>
            
            <h6>YouTube</h6>
        </div>
    </div>
);

/**
 * The icon is shown in the list of all elements.
 * @param selectHandler {function} must be invoked when the element is selected
 * @returns {*}
 * @constructor
 */
export const YouTubeIcon = ({ selectHandler }) => (
    <div className={styling.elementIcon} onClick={() => selectHandler(YOUTUBE_TYPE)}>
        <div>
            <FaYoutube />
        </div>
        
        <h6>YouTube</h6>
        
        <p>Embedded YouTube video</p>
    </div>
);

/**
 * Settings for the YouTube element.
 * @param props {object} component props
 * @returns {*}
 * @constructor
 */
export const YouTubeSettings = (props) => {
    const [{ id, videoId, customId }, setState] = useState(youtubeModel);
    
    // Embedded YouTube video
    const youtubeVideo = useRef({});
    
    // Sets the source of the embedded YouTube video
    const setYoutubeSrc = useRef(debounce((videoId) => {
        youtubeVideo.current.src = 'https://www.youtube.com/embed/' + videoId;
    }, 400));
    
    /**
     * Handles input field changes.
     * @param name {string} name of the input field that was changed
     * @param value {string} value that was typed into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
        
        if (name === 'videoId') {
            setYoutubeSrc.current(value);
        }
    };
    
    /**
     * Saves the edited settings.
     */
    const save = () => {
        const payload = { ...youtubeModel, id, customId, videoId };
        props.saveSettings(payload);
    };
    
    /**
     * Sets the provided data to the component's state.
     */
    useEffect(() => {
        const { id, customId, videoId } = props;
        
        if (videoId) {
            youtubeVideo.current.src = 'https://www.youtube.com/embed/' + videoId;
        }
        
        setState(prevState => ({ ...prevState, id, customId, videoId }));
    }, [props]);
    
    return (
        <>
            <div className={styling.header}>
                <h3>YouTube Video</h3>
                <Badge size='tiny' type='info'>ID: {id}</Badge>
            </div>
            
            <div className={styling.row}>
                <div className={styling.column}>
                    <h6>Video ID</h6>
                    <InputField
                        name='videoId'
                        placeholder='Video ID'
                        value={videoId}
                        onChange={changeHandler}
                        size='small'
                        autoComplete='off'
                    />
                </div>
            </div>
            
            <div className={styling.youtubeVideoContainer}>
                <iframe src='https://www.youtube.com/embed/' title='video preview' ref={youtubeVideo} />
                <div hidden={videoId} />
            </div>
            
            <div hidden={props.isEditor}>
                <div className={styling.devZone} />
                <div className={styling.row}>
                    <div className={styling.column}>
                        <h6>Custom ID</h6>
                        <InputField
                            name='customId'
                            placeholder='Custom ID'
                            value={customId}
                            onChange={changeHandler}
                            size='small'
                        />
                    </div>
                </div>
            </div>
            
            <div className={styling.controls}>
                <Button size='smaller' color='light' onClick={props.closeSettings}>Cancel</Button>
                <Button size='smaller' onClick={save}>Save</Button>
            </div>
        </>
    );
};