import React, { useEffect, useState } from 'react';
import { FiType } from 'react-icons/fi';
import copy from 'fast-copy';

import InputField, { TextArea } from 'components/UI/inputField/InputField';
import Button from 'components/UI/button/Button';
import Badge from 'components/UI/badge/Badge';

import etalon from 'etalon';
import config from 'config';

import styling from '../../Builder.module.scss';

/**
 * Name of the headline type.
 * @type {string}
 */
export const HEADLINE_TYPE = 'headline';

/**
 * Basic headline model.
 * @type {{level: string, subtitle: {[p: string]: string}, id: string, type: string, title: {[p: string]: string}, customId: string}}
 */
export const headlineModel = {
    id: '',
    type: HEADLINE_TYPE,
    customId: '',
    title: {
        [etalon.locales.en_US]: ''
    },
    subtitle: {
        [etalon.locales.en_US]: ''
    },
    level: ''
};

/**
 * Headline block element shown in the post or page overview.
 * @param title {string} the title shown in the preview
 * @param subtitle {string} the subtitle shown in the preview
 * @param status status {string} the status of the element
 * @returns {*}
 */
export const HeadlineBlock = ({ title, subtitle, status }) => (
    <div className={styling.blockWrapper}>
        <div className={styling.icon}>
            <FiType />
        </div>
        
        <div className={styling.blockDetails}>
            <span className={styling.blockType}>HEADLINE</span>
            
            <Badge size='tiny' type='neutral' hidden={!status}>{status}</Badge>
            
            <h6>{title[etalon.locales.en_US] || '-'}</h6>
            
            <p className={styling.blockCaption}>{subtitle[etalon.locales.en_US] || ''}</p>
        </div>
    </div>
);

/**
 * The icon is shown in the list of all elements.
 * @param selectHandler {function} must be invoked when the element is selected
 * @returns {*}
 * @constructor
 */
export const HeadlineIcon = ({ selectHandler }) => (
    <div className={styling.elementIcon} onClick={() => selectHandler(HEADLINE_TYPE)}>
        <div>
            <FiType />
        </div>
        
        <h6>Headline</h6>
        
        <p>simple headline element</p>
    </div>
);

/**
 * Settings for the headline element.
 * @param props
 * @returns {*}
 * @constructor
 */
export const HeadlineSettings = (props) => {
    const [{ id, customId, title, subtitle, level }, setState] = useState(headlineModel);
    
    
    /**
     * Saves the edited settings.
     */
    const save = () => {
        const payload = { ...headlineModel, id, customId, title, subtitle, level };
        props.saveSettings(payload);
    };
    
    
    /**
     * Handles input field changes.
     * @param target {object} DOM node of the changed element
     */
    const changeHandler = ({ target }) => {
        const locale = target.getAttribute('data-caasy-locale') || '';
        
        setState(prevState => {
            prevState = copy(prevState);
            
            if (locale) {
                prevState[target.name][locale] = target.value;
            } else {
                prevState[target.name] = target.value;
            }
            
            return prevState;
        });
    };
    
    
    /**
     * Sets the provided data to the component's state.
     */
    useEffect(() => {
        const { id, customId, title, subtitle, level } = props;
        
        setState(prevState => ({ ...prevState, id, customId, title, subtitle, level }));
    }, [props]);
    
    return (
        <>
            <div className={styling.header}>
                <h3>Headline</h3>
                <Badge size='tiny' type='info'>{id}</Badge>
            </div>
            
            <div className={styling.row}>
                <div className={styling.column}>
                    <h6>Title</h6>
                    <InputField
                        name='title'
                        data-caasy-locale={etalon.locales.en_US}
                        placeholder='Title'
                        value={title[etalon.locales.en_US] || ''}
                        onChange={changeHandler}
                        size='small'
                        test={config.regex.maxLength(etalon.maxLength.text)}
                    />
                </div>
            </div>
            
            <div className={styling.row}>
                <div className={styling.fullWidthColumn}>
                    <h6>Subtitle</h6>
                    <div className={styling.textArea}>
                        <TextArea
                            name='subtitle'
                            data-caasy-locale={etalon.locales.en_US}
                            value={subtitle[etalon.locales.en_US] || ''}
                            placeholder='Enter a subtitle...'
                            onChange={changeHandler}
                            test={config.regex.maxLength(etalon.maxLength.text)}
                        />
                    </div>
                </div>
            </div>
            
            
            <div hidden={props.isEditor}>
                <div className={styling.devZone} />
                <div className={styling.row}>
                    <div className={styling.column}>
                        <h6>Custom ID</h6>
                        <InputField
                            name='customId'
                            placeholder='Custom Id'
                            value={customId}
                            onChange={changeHandler}
                            size='small'
                            test={config.regex.maxLength(etalon.maxLength.id)}
                        />
                    </div>
                </div>
            </div>
            
            
            <div className={styling.controls}>
                <Button size='smaller' color='light' onClick={props.closeSettings}>Cancel</Button>
                <Button size='smaller' onClick={save}>Save</Button>
            </div>
        </>
    );
};