import React, { useEffect, useState } from 'react';
import { FaHtml5 } from 'react-icons/fa';

import InputField, { TextArea } from 'components/UI/inputField/InputField';
import Badge from 'components/UI/badge/Badge';
import Button from 'components/UI/button/Button';

import config from 'config';
import etalon from 'etalon';

import styling from '../../Builder.module.scss';

/**
 * Name of the type.
 * @type {string}
 */
export const HTML_TYPE = 'html';


/**
 * Basic HTML model.
 * @type {{html: string, id: string, type: string, customId: string}}
 */
export const htmlModel = {
    id: '',
    type: HTML_TYPE,
    customId: '',
    html: ''
};


/**
 * HTML block element shown in the Post or Page overview.
 * @param status {string} the status of the element
 * @param html {string} HTML text
 * @returns {*}
 * @constructor
 */
export const HtmlBlock = ({ status, html }) => (
    <div className={styling.blockWrapper}>
        <div className={styling.icon}>
            <FaHtml5 />
        </div>
        
        <div className={styling.blockDetails}>
            <span className={styling.blockType}>HTML</span>
            
            <Badge size='tiny' type='neutral' hidden={!status}>{status}</Badge>
            
            <h6>HTML</h6>
            
            <p className={styling.blockCaption}>{html}</p>
        </div>
    </div>
);


/**
 * The icon shown in the list of all elements.
 * @param selectHandler {function} must be invoked when the element is selected
 * @returns {*}
 * @constructor
 */
export const HtmlIcon = ({ selectHandler }) => (
    <div className={styling.elementIcon} onClick={() => selectHandler(HTML_TYPE)}>
        <div>
            <FaHtml5 />
        </div>
        
        <h6>HTML</h6>
        
        <p>Raw HTML snippet</p>
    </div>
);


/**
 * The settings for the HTML element.
 * @param props {object} component props
 * @returns {*}
 * @constructor
 */
export const HtmlSettings = (props) => {
    const [{ id, customId, html }, setState] = useState(htmlModel);
    
    
    /**
     * Handles input field changes.
     * @param name {string} name of the input field
     * @param value {string} the text that was entered into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };
    
    
    /**
     * Saves the edited settings.
     */
    const save = () => {
        const payload = { ...htmlModel, id, customId, html };
        props.saveSettings(payload);
    };
    
    
    /**
     * Sets the provided data to the component's state.
     */
    useEffect(() => {
        const { id, customId, html } = props;
        setState(prevState => ({ ...prevState, id, customId, html }));
    }, [props]);
    
    
    return (
        <>
            <div className={styling.header}>
                <h3>HTML</h3>
                <Badge size='tiny' type='info'>ID: {id}</Badge>
            </div>
            
            <div className={styling.row}>
                <div className={styling.fullWidthColumn}>
                    <div className={styling.textArea}>
                        <TextArea
                            name='html'
                            placeholder='Enter HTML here...'
                            value={html}
                            onChange={changeHandler}
                            size='small'
                            test={config.regex.maxLength(etalon.maxLength.text)}
                            code
                        />
                    </div>
                </div>
            </div>
            
            <div hidden={props.isEditor}>
                <div className={styling.devZone} />
                <div className={styling.row}>
                    <div className={styling.column}>
                        <h6>Custom ID</h6>
                        <InputField
                            name='customId'
                            placeholder='Custom Element ID'
                            value={customId}
                            onChange={changeHandler}
                            size='small'
                            test={config.regex.maxLength(etalon.maxLength.text)}
                        />
                    </div>
                </div>
            </div>
            
            <div className={styling.controls}>
                <Button size='smaller' color='light' onClick={props.closeSettings}>Cancel</Button>
                <Button size='smaller' onClick={save}>Save</Button>
            </div>
        </>
    );
};