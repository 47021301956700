import React, { createContext, useCallback, useContext, useReducer } from 'react';

const Context = createContext({});

const initialState = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    sites: [],
    currentSite: {},
    createdAt: '',
    updatedAt: '',
    error: ''
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return { ...state, ...action.payload };
        case 'setCurrentSite':
            return { ...state, currentSite: action.payload };
        case 'updateCurrentSite':
            return { ...state, currentSite: { ...state.currentSite, ...action.payload } };
        case 'reset':
            return initialState;
        default:
            return state;
    }
};

export const StoreProvider = ({ children }) => {
    const [store, dispatch] = useReducer(reducer, initialState);
    
    const setError = useCallback((error) => {
        const message = (error instanceof Error) ? 'An unexpected error occurred.' : error;
        
        dispatch({ type: 'update', payload: { error: message } });
        
        setTimeout(() => {
            dispatch({ type: 'update', payload: { error: '' } });
        }, 10000);
    }, []);
    
    return (
        <Context.Provider value={[store, dispatch, setError]}>
            {children}
        </Context.Provider>
    );
};

export const useStore = () => useContext(Context);