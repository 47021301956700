import React from 'react';

import avatars from 'assets/images/avatars/';

import styling from './Avatar.module.scss';

const Avatar = ({ avatarId = '1', size = 'standard', stacked, selectHandler, selected }) => {
    const style = styling.avatar + ' ' + styling[size] + ' ' + (stacked && styling.stacked) + ' ' + (selected && styling.selected);
    
    return (
        <img
            src={avatars[avatarId]}
            alt='avatar'
            className={style}
            onClick={selectHandler}
        />
    );
};

export default Avatar;