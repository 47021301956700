const byteFormatter = (bytes, decimals = 2) => {
    if (!bytes) {
        return '0 Bytes';
    }
    
    const base = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    
    decimals = decimals < 0 ? 0 : decimals
    
    const index = Math.floor(Math.log(bytes) / Math.log(base));
    
    return parseFloat((bytes / Math.pow(base, index)).toFixed(decimals)) + ' ' + sizes[index];
};

export default byteFormatter;