import React from 'react';

import AvailableSoon from 'components/UI/banner/AvailableSoon';
import Title from 'components/UI/title/Title';

const News = () => {
    return (
        <>
            <Title title='News' subtitle='Keep your users informed' />
            
            <AvailableSoon>
                This feature is currently in the works, please check back soon. With news you will be able to post news
                to keep your visitors informed.
            </AvailableSoon>
        </>
    );
};

export default News;