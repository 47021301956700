import React from 'react';

import Button from 'components/UI/button/Button';
import Modal from 'components/UI/modal/Modal';

import { icons } from '../elements/';

import styling from './AddElement.module.scss';

const AddElement = ({ isOpen, close, selectHandler, isNested }) => (
    <Modal open={isOpen}>
        <h3>Add Element</h3>
        
        <div className={styling.wrapper}>
            {icons.map(category => (
                <div key={category.category}>
                    <h6 className={styling.categoryTitle}>{category.category}</h6>
                    
                    <div className={styling.grid}>
                        {category.elementsInCategories.map((Element, i) => (
                            <Element key={i} selectHandler={selectHandler} hidden={isNested} />
                        ))}
                    </div>
                </div>
            ))}
        </div>
        
        <div className={styling.controls}>
            <Button size='smaller' color='light' onClick={close}>Cancel</Button>
            <Button size='smaller'>Select</Button>
        </div>
    </Modal>
);

export default AddElement;