import React from 'react';

import Badge from '../badge/Badge';

import styling from './ConfirmationNotice.module.scss';

const ConfirmationNotice = ({ hide }) => (
    <div className={styling.confirmation} hidden={hide}>
        <Badge type='info'>Please check your inbox and confirm your email address</Badge>
    </div>
);

export default ConfirmationNotice;