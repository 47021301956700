export default {
    // auth
    signUp: () => ({ url: '/auth/signup?mode=cookie', method: 'POST', noAuthRequired: true }),
    signIn: () => ({ url: '/auth/signin?mode=cookie', method: 'POST', noAuthRequired: true }),
    confirmEmail: () => ({ url: '/auth/confirmemail', method: 'POST', noAuthRequired: true }),
    resetPassword: () => ({ url: '/auth/resetpassword', method: 'POST', noAuthRequired: true }),
    setNewPassword: () => ({ url: '/auth/newpassword', method: 'POST', noAuthRequired: true }),
    // user
    getUser: () => ({ url: '/user/', method: 'GET' }),
    updateUser: () => ({ url: '/user/', method: 'PUT' }),
    deleteUser: () => ({ url: '/user/', method: 'DELETE' }),
    // site
    createSite: () => ({ url: '/site/', method: 'POST' }),
    updateSite: ({ siteId }) => ({ url: '/site/' + siteId, method: 'PUT' }),
    deleteSite: ({ siteId }) => ({ url: '/site/' + siteId, method: 'DELETE' }),
    allUsers: ({ siteId }) => ({ url: '/site/' + siteId + '/users', method: 'GET' }),
    inviteUser: ({ siteId, email, role }) => ({ url: '/site/' + siteId + '?email=' + email + '&role=' + role, method: 'POST' }),
    removeUser: ({ siteId, userId }) => ({ url: '/site/' + siteId + '/user?user-id=' + userId, method: 'DELETE' }),
    getActivity: ({ siteId }) => ({ url: '/site/' + siteId + '/activity', method: 'GET' }),
    // post
    getPost: ({ postId = '' }) => ({ url: '/post/' + postId, method: 'GET' }),
    getAllPosts: ({ page = 1, siteId = 0 }) => ({ url: `/post/?site-id=${siteId}&page=${page}`, method: 'GET' }),
    createPost: () => ({ url: '/post/', method: 'POST' }),
    updatePost: ({ postId, siteId }) => ({ url: '/post/' + postId + '?siteId=' + siteId, method: 'PUT' }),
    deletePost: ({ postId, siteId }) => ({ url: '/post/' + postId + '?siteId=' + siteId, method: 'DELETE' }),
    // page
    createPage: () => ({ url: '/page/', method: 'POST' }),
    getPage: ({ pageId = '' }) => ({ url: '/page/' + pageId, method: 'GET' }),
    getAllPages: ({ page = 1, siteId = 0 }) => ({ url: `/page/?site-id=${siteId}&page=${page}`, method: 'GET' }),
    updatePage: ({ pageId, siteId }) => ({ url: '/page/' + pageId + '?siteId=' + siteId, method: 'PUT' }),
    deletePage: ({ pageId, siteId }) => ({ url: '/page/' + pageId + '?siteId=' + siteId, method: 'DELETE' }),
    // media
    getAllMedia: ({ siteId }) => ({ url: `/media/${siteId}/all`, method: 'GET' }),
    deleteMedia: ({ siteId, mediaId }) => ({ url: `/media/${siteId}/${mediaId}`, method: 'DELETE' })
}