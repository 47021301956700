import React from 'react';
import { Link } from 'react-router-dom';

import caasy from 'assets/images/logos/caasy.svg';

import styling from './Menu.module.scss';

const Menu = ({ reference = null }) => (
    <nav className={styling.nav} ref={reference}>
        <div className={styling.content}>
            <Link to='/auth'>
                <img src={caasy} alt='caasy logo' />
            </Link>
    
            <ul>
                <li><Link to='/auth/signin'>Sign In</Link></li>
                <li><Link to='/auth/signup'>Sign Up</Link></li>
            </ul>
        </div>
    </nav>
);

export default Menu;