import React, { useEffect, useRef } from 'react';

import { useStore } from 'context';

import styling from './Toast.module.scss';

const Toast = () => {
    const [{ error }] = useStore();
    
    const toast = useRef({});
    
    useEffect(() => {
        if (error) {
            toast.current.classList.add(styling.open);
        } else {
            toast.current.classList.remove(styling.open);
        }
    }, [error]);
    
    return (
        <div className={styling.toast} ref={toast}>
            <h6>Failed</h6>
            <p>{error || 'An unexpected error occurred.'}</p>
        </div>
    );
};

export default Toast;