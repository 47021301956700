import axios from 'axios';

import actions from './actions';
import config from 'config';
import utils from 'utils';

const client = async (type, payload = {}, variables) => {
    const action = actions[type](variables);
    
    const jwt = localStorage.getItem('access-pass');
    
    const opts = {
        method: action.method,
        headers: {
            'Content-Type': 'application/json',
            ...(!action.noAuthRequired) && { 'Authorization': 'Bearer ' + jwt }
        },
        credentials: 'include',
        ...(action.method === 'POST' || action.method === 'PUT' || action.method === 'DELETE') && { body: JSON.stringify(payload) }
    };
    
    let res = await fetch(config.connectivity.backendURL + action.url, opts);
    
    // Cloud Run sometimes responds with a 429
    // if a new container is spinning up or
    // cold start takes too long. In that case
    // the request is send again after around 700ms.
    if (res.status === 429) {
        await utils.sleep(700);
        res = await fetch(config.connectivity.backendURL + action.url, opts);
    }
    
    if (!res.ok) {
        const body = await res.json();
        const error = new Error(body.message || 'failed to fetch with unknown reason');
        
        error.code = res.status;
        
        throw error;
    }
    
    return await res.json();
};

export const externalRequest = async (url) => {
    return await axios(url);
};

export const upload = async (siteId, data, progressHandler) => {
    if (!siteId) {
        throw new Error('no site Id provided');
    }
    
    if (!data) {
        throw new Error('no data provided');
    }
    
    if (!progressHandler) {
        throw new Error('no progress handler provided');
    }
    
    const url = config.connectivity.backendURL + '/media/upload?site-id=' + siteId;
    
    const jwt = localStorage.getItem('access-pass');
    
    const opts = {
        onUploadProgress: progressHandler,
        headers: {
            'Authorization': 'Bearer ' + jwt
        },
        withCredentials: true
    };
    
    return await axios.post(url, data, opts);
};

export default client;