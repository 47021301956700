import React, { useEffect, useRef, useState } from 'react';
import { FiMove, FiPlus, FiTrash2 } from 'react-icons/fi';
import { useParams, withRouter } from 'react-router-dom';
import { motion } from 'framer-motion';
import isEqual from 'lodash/isEqual';
import copy from 'fast-copy';
import set from 'lodash/set';
import get from 'lodash/get';

import AddElement from './addElement/AddElement';
import Modal from 'components/UI/modal/Modal';
import Metadata from './metadata/Metadata';
import Toolbar from './toolbar/Toolbar';

import { blocks, models, settings } from './elements/';
import { useStore } from 'context';
import etalon from 'etalon';
import client from 'client';
import utils from 'utils';

import styling from './Builder.module.scss';

const Builder = (props) => {
    // Store
    const [store, , setError] = useStore();
    
    // State
    const [{ data, dataType, indexToEdit, addNewElementModalOpen, isSaving }, setState] = useState({
        data: {},
        dataType: 'post',
        indexToEdit: '',
        addNewElementModalOpen: false,
        isSaving: false
    });
    
    // Ref
    const elementsHistory = useRef([]);
    const elementHistoryPointer = useRef(-1);
    
    // Params
    const { currentSite, postId, pageId } = useParams();
    
    // Role
    const isEditor = store?.currentSite?.role === 'editor';
    
    /**
     * Toggles the modal visibility to edit an element.
     * @param indexToEdit
     */
    const toggleSettingsModal = (indexToEdit) => {
        if (typeof indexToEdit !== 'string') {
            indexToEdit = '';
        }
        setState(prevState => ({ ...prevState, indexToEdit }));
    };
    
    
    /**
     * Toggles the visibility of the modal to add a new element.
     */
    const toggleAddElementModal = () => {
        setState(prevState => ({ ...prevState, addNewElementModalOpen: !prevState.addNewElementModalOpen }));
    };
    
    
    /**
     * Compares elements with the previous
     * elements and tags them accordingly.
     * @param elements {array} current elements
     * @param previousElements {array} elements of the previous version
     * @returns {*}
     */
    const tagElements = (elements = [], previousElements = []) => {
        return elements.map(element => {
            const metadata = {};
            
            const prevElement = previousElements.find(x => x.id === element.id);
            
            if (prevElement && !isEqual(element, prevElement)) {
                metadata.status = 'recently updated';
            }
            
            if (!prevElement) {
                metadata.status = 'recently added';
            }
            
            element[Symbol.for('metadata')] = metadata;
            
            return element;
        });
    };
    
    
    /**
     * Updates the state with the new element.
     * @param elements {array} elements
     */
    const updateElements = (elements) => {
        // Add to history and ensure the history array doesn't get too large
        elementsHistory.current.push(data?.elements || []);
        elementsHistory.current = elementsHistory.current.slice(Math.max(elementsHistory.current.length - 10, 0));
        
        // Update elements history pointer
        elementHistoryPointer.current = elementsHistory.current.length - 1;
        
        setState(prevState => ({
            ...prevState,
            addNewElementModalOpen: false,
            indexToEdit: '',
            data: { ...prevState.data, elements }
        }));
    };
    
    
    /**
     * Changes the position of nested elements, such as
     * elements in a container.
     * @param ei {number} index of the element that contains the nested element, such as a container
     * @param i {number} the index of the nested element that should be moved to a new position
     * @param np {number} the index of the new position of the nested element
     */
    const changeNestedElementPosition = (ei, i, np) => {
        const els = copy(data.elements);
        
        if (np < 0 || np >= els[ei].elements.length) {
            return;
        }
        
        [els[ei].elements[i], els[ei].elements[np]] = [els[ei].elements[np], els[ei].elements[i]];
        
        updateElements(els);
    };
    
    
    /**
     * Handles input changes of data.
     */
    const dataChangeHandler = ({ target }) => {
        const locale = target.getAttribute('data-caasy-locale');
        const tmpData = { ...data };
        
        if (locale) {
            set(tmpData, target.name + '.' + locale, target.value);
        } else {
            tmpData[target.name] = target.value;
        }
        
        setState(prevState => ({ ...prevState, data: tmpData }));
    };
    
    
    /**
     * Handles checkbox changes.
     * @param name {string} name of the attribute that should be changed
     * @param checked {boolean} determines if the checkbox is checked
     */
    const dataSwitchHandler = ({ target: { name, checked } }) => {
        const tmpData = { ...data };
        tmpData[name] = checked;
        setState(prevState => ({ ...prevState, data: tmpData }));
    };
    
    
    /**
     * Handles changes of the preview image and stores the selected.
     * preview image name in the state.
     * @param previewImageName
     */
    const previewImageChangeHandler = (previewImageName) => {
        const tmpData = { ...data };
        tmpData.previewImageName = previewImageName;
        setState(prevState => ({ ...prevState, data: tmpData }));
    };
    
    
    /**
     * Handles drag over.
     * @param e {object} drag event
     */
    const dragOver = (e) => {
        e.persist();
        e.preventDefault();
        e.target.style.height = '7rem';
    };
    
    
    /**
     * Handles drag leave.
     * @param e {object} drag event
     */
    const dragLeave = (e) => {
        e.persist();
        e.preventDefault();
        e.target.style.height = '2.5rem';
    };
    
    
    /**
     * Handles drag start.
     * @param e {object} drag event
     */
    const dragStart = (e) => {
        const index = e.target.getAttribute('data-element-id');
        e.dataTransfer.setData('text', index);
    };
    
    
    /**
     * Handles drop.
     * @param e {object} drop event
     */
    const drop = (e) => {
        e.persist();
        e.preventDefault();
        e.target.style.height = '2.5rem';
        
        const elements = copy(data.elements);
        
        const startIndex = +e.dataTransfer.getData('text');
        let targetIndex = +e.target.getAttribute('data-dropzone-id');
        
        const element = elements[startIndex];
        
        elements.splice(startIndex, 1);
        elements.splice(startIndex < targetIndex ? targetIndex - 1 : targetIndex, 0, element);
        
        updateElements(elements);
    };
    
    
    /**
     * Appends a new element block of a given type at the end.
     * @param type {string} type of the element
     * @param nestedIndex {number} if provided, it will add a new element to the element with this index
     */
    const addNewElementBlock = (type, nestedIndex) => {
        const rawModel = copy(models[type]);
        
        rawModel.id = Date.now().toString() + '-' + utils.random(5);
        
        const elements = copy(data?.elements || []);
        
        if (nestedIndex !== undefined) {
            elements[nestedIndex].elements.push(rawModel);
        } else {
            elements.push(rawModel);
        }
        
        updateElements(elements);
    };
    
    
    /**
     * Saves an edited element.
     */
    const saveEditedSettings = (payload) => {
        const elements = copy(data.elements);
        set(elements, indexToEdit, payload);
        updateElements(elements);
    };
    
    
    /**
     * Deletes an element block.
     * @param index {number} the index of the element block that should be deleted
     * @param nestedIndex {number} if provided, it deleted the nested element with the given index
     */
    const deleteElement = (index, nestedIndex) => {
        const elements = copy(data.elements || []);
        
        if (nestedIndex !== undefined) {
            elements[index].elements.splice(nestedIndex, 1);
        } else {
            elements.splice(index, 1)
        }
        
        updateElements(elements);
    };
    
    
    /**
     * Determines the props for an element block.
     * @param type {string} the type of the element block
     * @param i {number} index of the element block
     * @returns {{}} the element blocks props as object
     */
    const getElementBlockProps = (type, i) => {
        return { ...(type !== 'container' && { onClick: () => toggleSettingsModal(i.toString()) }) };
    };
    
    
    /**
     * Resets elements to a previous state.
     */
    const undo = () => {
        if (elementHistoryPointer.current < 0) {
            return;
        }
        
        const elements = copy(elementsHistory.current[elementHistoryPointer.current]);
        setState(prevState => ({ ...prevState, data: { ...prevState.data, elements } }));
        
        elementHistoryPointer.current -= 1;
    };
    
    
    /**
     * Saves or updates data.
     * @returns {Promise<void>}
     */
    const saveData = async () => {
        try {
            setState(prevState => ({ ...prevState, isSaving: true }));
            
            // Distinguish between posts and pages
            const updateAction = dataType === 'post' ? 'updatePost' : 'updatePage';
            const createAction = dataType === 'post' ? 'createPost' : 'createPage';
            const redirectLink = dataType === 'post' ? '/posts' : '/pages';
            
            if (postId || pageId) {
                await client(updateAction, data, { postId, pageId, siteId: currentSite });
                setTimeout(() => setState(prevState => ({ ...prevState, isSaving: false })), 500);
            } else {
                const dataCopy = copy(data);
                dataCopy.siteId = currentSite;
                await client(createAction, dataCopy);
            }
            
            props.history.push('/' + currentSite + redirectLink);
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };
    
    
    /**
     * Determines if the build should show a post or a
     * page and tags existing elements.
     */
    useEffect(() => {
        if (props.data?.elements?.length) {
            const { elements, previousElements } = props.data;
            props.data.elements = tagElements(elements, previousElements);
        }
        
        setState(prevState => ({ ...prevState, data: props.data || {}, dataType: props.dataType }));
    }, [props.data, props.dataType]);
    
    
    // Variables
    const elementToEdit = get(data?.elements, indexToEdit) || {};
    const ElementSettings = settings[elementToEdit.type || 'fallback'] || settings.fallback;
    
    
    return (
        <>
            <Toolbar undo={undo} reset={() => updateElements([])} preventReset={isEditor} />
            
            <div className={styling.wrapper}>
                <motion.div className={styling.elements} layoutTransition={etalon.transition}>
                    <div
                        className={styling.dropZone}
                        data-dropzone-id='0'
                        onDragOver={dragOver}
                        onDragLeave={dragLeave}
                        onDrop={drop}
                    />
                    
                    {(data?.elements || []).map((element, i) => {
                        const Element = blocks[element.type || 'fallback'] || blocks.fallback;
                        
                        return (
                            <motion.div key={element.id} positionTransition>
                                <div className={styling.block} data-element-id={i} onDragStart={dragStart} draggable>
                                    <div className={styling.element} {...getElementBlockProps(element.type, i)}>
                                        <Element
                                            {...element}
                                            index={i}
                                            deleteElement={deleteElement}
                                            addElement={addNewElementBlock}
                                            changePosition={changeNestedElementPosition}
                                            openSettings={toggleSettingsModal}
                                            isEditor={isEditor}
                                            status={element[Symbol.for('metadata')]?.status || ''}
                                        />
                                    </div>
                                    
                                    <div className={styling.actions}>
                                        <div className={styling.action} hidden={isEditor}><FiMove /></div>
                                        <div className={styling.action} onClick={() => deleteElement(i)} hidden={isEditor}>
                                            <FiTrash2 />
                                        </div>
                                    </div>
                                </div>
                                
                                <div
                                    className={styling.dropZone}
                                    data-dropzone-id={i + 1}
                                    onDragOver={dragOver}
                                    onDragLeave={dragLeave}
                                    onDrop={drop}
                                />
                            </motion.div>
                        );
                    })}
                    
                    <div className={styling.addElementButton} onClick={toggleAddElementModal} hidden={isEditor}>
                        <FiPlus />
                    </div>
                </motion.div>
                
                
                <Metadata
                    id={data.id}
                    title={data.title || ''}
                    description={data.description || ''}
                    isPublished={data.isPublished}
                    previewImageName={data.previewImageName}
                    dataType={dataType}
                    changeHandler={dataChangeHandler}
                    switchHandler={dataSwitchHandler}
                    previewImageChangeHandler={previewImageChangeHandler}
                    saveHandler={saveData}
                    isSaving={isSaving}
                />
            </div>
            
            
            <Modal open={!!indexToEdit}>
                <ElementSettings
                    {...elementToEdit}
                    isEditor={isEditor}
                    closeSettings={toggleSettingsModal}
                    saveSettings={saveEditedSettings}
                />
            </Modal>
            
            
            <AddElement
                isOpen={addNewElementModalOpen}
                close={toggleAddElementModal}
                selectHandler={addNewElementBlock}
            />
        </>
    );
};

export default withRouter(Builder);