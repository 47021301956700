import React from 'react';
import { FaUndo } from 'react-icons/fa';
import { FiTrash2 } from 'react-icons/fi';

import styling from './Toolbar.module.scss';

const Toolbar = ({ undo, reset, preventReset }) => (
    <div className={styling.toolbar}>
        <div className={styling.icon} onClick={reset} hidden={preventReset}>
            <FiTrash2 />
        </div>
        
        <div className={styling.icon} onClick={undo}>
            <FaUndo />
        </div>
    </div>
);

export default Toolbar;