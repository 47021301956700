import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MediaItem } from 'components/mediaLibrary/MediaLibrary';
import InputField, { TextArea } from 'components/UI/inputField/InputField';
import Button from 'components/UI/button/Button';
import Toggle from 'components/UI/toggle/Toggle';
import Panel from 'components/UI/panel/Panel';
import Modal from 'components/UI/modal/Modal';

import styling from './Metadata.module.scss';

import { useStore } from 'context';
import config from 'config';
import client from 'client';
import etalon from 'etalon';

/**
 * Fetches and displays all images available for the current site.
 * @param selectHandler {function} is invoked when a media item is clicked
 * @returns {*}
 * @constructor
 */
const PreviewImagePicker = ({ selectHandler }) => {
    const [, , setError] = useStore();
    const [allImages, setAllImages] = useState([]);
    const { currentSite } = useParams();
    
    /**
     * Fetches all media items stores all images in the state.
     * @type {(...args: any[]) => any}
     */
    const fetchAllImages = useCallback(async () => {
        try {
            const allMediaItems = await client('getAllMedia', null, { siteId: currentSite });
            setAllImages(allMediaItems);
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }, [currentSite, setError]);
    
    /**
     * Fetches all images if the fetchAllImage
     * function updated.
     */
    useEffect(() => {
        fetchAllImages();
    }, [fetchAllImages]);
    
    return (
        <div className={styling.items}>
            {allImages.map(mediaItem => (
                <MediaItem
                    key={mediaItem.id}
                    mediaItem={mediaItem}
                    selectHandler={() => selectHandler(mediaItem.name)}
                />
            ))}
        </div>
    );
};

/**
 * Shows a post's or page's metadata.
 * @param props {object} component props
 * @returns {*}
 * @constructor
 */
const Metadata = (props) => {
    // Deconstructing
    const {
        id,
        title,
        description,
        isPublished,
        previewImageName,
        dataType,
        changeHandler,
        switchHandler,
        previewImageChangeHandler,
        saveHandler,
        isSaving
    } = props;
    
    // State
    const [modalOpen, setModalOpen] = useState(false);
    const [locale] = useState(etalon.locales.en_US);
    
    // Ref
    const previewImageBox = useRef({});
    
    // Base URL
    const thumbnailBaseURL = config.connectivity.imageThumbnailBucket + '400x400_';
    
    /**
     * Passes the selected image's name to the save
     * function and closes the modal.
     * @param previewImageName
     */
    const previewImageHandler = (previewImageName) => {
        previewImageBox.current.style.backgroundImage = `url("${thumbnailBaseURL + previewImageName}")`;
        previewImageChangeHandler(previewImageName);
        setModalOpen(false);
    };
    
    useEffect(() => {
        
        if (previewImageName) {
            previewImageBox.current.style.backgroundImage = `url("${thumbnailBaseURL + previewImageName}")`;
        }
    }, [previewImageName, thumbnailBaseURL]);
    
    return (
        <>
            <Panel>
                <div className={styling.metadata}>
                    <h3>{title[locale] || 'Metadata'}</h3>
                    
                    <div hidden={dataType !== 'post'}>
                        <h6>Preview Image</h6>
                        <div
                            ref={previewImageBox}
                            className={styling.previewImage}
                            onClick={() => setModalOpen(true)}
                        />
                    </div>
                    
                    <div className={styling.row}>
                        <h6>Name</h6>
                        <InputField
                            name='title'
                            data-caasy-locale={locale}
                            placeholder='Title'
                            size='small'
                            value={title[locale] || ''}
                            onChange={changeHandler}
                        />
                    </div>
                    
                    <div className={styling.row} hidden={dataType !== 'post'}>
                        <h6>Description</h6>
                        <TextArea
                            name='description'
                            data-caasy-locale={locale}
                            placeholder='Description'
                            value={description[locale] || ''}
                            onChange={changeHandler}
                        />
                    </div>
    
                    <div className={styling.row} hidden={!id}>
                        <h6>{dataType === ('post' ? 'Post' : 'Page')} ID</h6>
                        <div>{id}</div>
                    </div>
                    
                    <div className={styling.row}>
                        <h6>Publish</h6>
                        
                        <div className={styling.flex}>
                            <div>Publish content now</div>
                            <Toggle name='isPublished' checked={isPublished || false} onChange={switchHandler} />
                        </div>
                    </div>
                    
                    <div className={styling.controls}>
                        <Button size='smaller' onClick={saveHandler} isLoading={isSaving}>Save</Button>
                    </div>
                </div>
            </Panel>
            
            <Modal open={modalOpen}>
                <h3>Select Preview Image</h3>
                
                <PreviewImagePicker selectHandler={previewImageHandler} />
                
                <div className={styling.controls}>
                    <Button size='smaller' color='light' onClick={() => setModalOpen(false)}>Cancel</Button>
                </div>
            </Modal>
        </>
    )
};

export default Metadata;