import React from 'react';

import styling from './Card.module.scss';

const Card = ({ children }) => (
    <div className={styling.card}>
        {children}
    </div>
);

export const Title = ({ icon, children }) => (
    <div className={styling.title}>{icon} <span>{children}</span></div>
);

export default Card;