import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import ConfirmationNotice from 'components/UI/confirmationNotice/ConfirmationNotice';
import Spinner from 'components/UI/spinner/Spinner';
import Sidebar from '../sidebar/Sidebar';
import Nav from '../nav/Nav';

import { useStore } from 'context';
import client from 'client';
import utils from 'utils';

import styling from './Blueprint.module.scss';

// These paths require a site to be selected
const pathsOfSite = ['posts', 'post', 'pages', 'page', 'news', 'media', 'users', 'activity', 'settings', 'integrations'];

// These paths are global and don't depend
// on a selected site.
const globalPaths = ['feedback', 'account'];

const Blueprint = ({ children, history, location }) => {
    const [store, dispatch] = useStore();
    
    const [loading, setLoading] = useState(true);
    
    
    /**
     * Loads the currently selected site depending on the URL.
     * @type {function(*): {}}
     */
    const getCurrentSite = useCallback((sites) => {
        const currentPath = location.pathname.split('/') || [];
        
        if (currentPath[2] && pathsOfSite.includes(currentPath[2])) {
            return sites?.find(x => x.id === currentPath[1]) || null;
        }
        
        return null;
        
    }, [location.pathname]);
    
    
    /**
     * Fetches the user's data.
     * @type {function}
     */
    const fetchUserData = useCallback(async () => {
        try {
            // Check is already signed in
            const accessPass = localStorage.getItem('access-pass');
            const expirationTime = localStorage.getItem('expiration-time');
            
            if (!accessPass || !expirationTime || new Date(parseInt(expirationTime)) < new Date()) {
                history.push({ pathname: '/auth/signin', state: { redirectUrl: location.pathname } });
                return;
            }
            
            // Fetch user data
            const user = await client('getUser');
            
            // Set expiration time
            utils.expirationHandler(history, +expirationTime);
            
            // Update document title
            document.title = 'Caasy CMS | ' + user.email;
            
            // Check if a specific site is opened and set it
            const currentSite = getCurrentSite(user.sites || []) || {};
            
            // Update state
            dispatch({ type: 'update', payload: { ...user, currentSite } });
            setLoading(false);
            
        } catch (error) {
            console.error(error);
            localStorage.clear();
            history.push('/auth/signin');
        }
    }, [dispatch, getCurrentSite, history, location.pathname]);
    
    
    /**
     * Fetches the users's data if it's not available.
     */
    useEffect(() => {
        if (!store.id) {
            fetchUserData();
        } else if (!store.currentSite?.id) {
            const currentSite = getCurrentSite(store.sites || []);
            
            if (currentSite) {
                dispatch({ type: 'update', payload: { currentSite } });
            } else if (!globalPaths.includes(location.pathname.split('/')[1])) {
                history.push('/sites');
            }
        }
    }, [fetchUserData, dispatch, store.sites, store.currentSite, location.pathname, store.id, getCurrentSite, history]);
    
    
    // Spinner
    const spinner = (
        <div className={styling.spinner}>
            <Spinner invert />
        </div>
    );
    
    
    // Layout
    const blueprint = (
        <>
            <Sidebar />
            <Nav />
            <ConfirmationNotice hide={store.isConfirmed} />
            
            <div className={styling.content}>
                <main>
                    {children}
                </main>
            </div>
        </>
    );
    
    
    return (loading && !store.id) ? spinner : blueprint;
};

export default withRouter(Blueprint);