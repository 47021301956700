import React from 'react';

import styling from './Panel.module.scss';

const Panel = ({ children }) => (
    <aside className={styling.panel}>
        <div className={styling.wrapper}>{children}</div>
    </aside>
);

export default Panel;