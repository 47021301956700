import React, { useCallback, useEffect, useState } from 'react';
import { FiUserPlus, FiUsers } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import InputField from 'components/UI/inputField/InputField';
import Dropdown from 'components/UI/dropdown/Dropdown';
import Card, { Title } from 'components/UI/card/Card';
import Spinner from 'components/UI/spinner/Spinner';
import MainTitle from 'components/UI/title/Title';
import Avatar from 'components/UI/avatar/Avatar';
import Button from 'components/UI/button/Button';
import Badge from 'components/UI/badge/Badge';

import { useStore } from 'context';
import client from 'client';
import config from 'config';

import styling from './UserManagement.module.scss';

const roles = [
    { id: 'editor', key: 'Editor', value: 'editor' },
    { id: 'creator', key: 'Creator', value: 'creator' },
    { id: 'admin', key: 'Admin', value: 'admin' }
];

const UserManagement = () => {
    // Store
    const [store, , setError] = useStore();
    
    // State
    const [{ users, email, role, isInviting, isLoading }, setState] = useState({
        users: [],
        email: '',
        role: 'editor',
        isInviting: false,
        isLoading: true
    });
    
    // Params
    const { currentSite } = useParams();
    
    // Check permission
    const isAdmin = store?.currentSite?.role === 'admin' || store?.currentSite?.role === 'owner';
    
    
    /**
     * Fetches all users that have access to the site.
     * @type {(...args: any[]) => any}
     */
    const fetchAllUsers = useCallback(async () => {
        try {
            const users = await client('allUsers', null, { siteId: currentSite }) || [];
            
            setTimeout(() => {
                setState(prevState => ({ ...prevState, users, isLoading: false }));
            }, 150);
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }, [currentSite, setError]);
    
    
    /**
     * Invites a new user to the site.
     * @returns {Promise<void>}
     */
    const inviteUser = async () => {
        try {
            setState(prevState => ({ ...prevState, isInviting: true }));
            
            const invitedUser = { id: Date.now(), email, role, isPending: true };
            
            await client('inviteUser', null, { siteId: currentSite, email, role });
            
            setState(prevState => ({
                ...prevState,
                email: '',
                role: 'editor',
                users: [...prevState.users, invitedUser],
                isInviting: false
            }));
            
            setTimeout(fetchAllUsers, 1800);
            
        } catch (error) {
            console.error(error);
            
            const invitedUser = { id: Date.now(), email, notFound: true };
            
            setState(prevState => ({
                ...prevState,
                email: '',
                users: [...prevState.users, invitedUser],
                isInviting: false
            }));
        }
    };
    
    /**
     * Removes a user from the site.
     * @param userId {string} the ID of the user that should be removed
     * @returns {Promise<void>}
     */
    const removeUser = async (userId) => {
        try {
            const updatedUsers = [...users].filter(x => x.id !== userId);
            setState(prevState => ({ ...prevState, users: updatedUsers }));
            
            await client('removeUser', null, { siteId: currentSite, userId });
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };
    
    /**
     * Handles input field changes.
     * @param name {string} name of the input field
     * @param value {string} text that was typed in into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };
    
    
    /**
     * Handles dropdown selections.
     * @param value {string} the selected value
     */
    const selectHandler = (value) => {
        setState(prevState => ({ ...prevState, role: value }));
    };
    
    
    /**
     * Fetches all users when the fetch user function changes.
     */
    useEffect(() => {
        fetchAllUsers();
    }, [fetchAllUsers]);
    
    
    //Validate email address
    const emailIsValid = RegExp(config.regex.email).test(email);
    
    
    return (
        <>
            <MainTitle title='User Management' subtitle='Add users to your site' />
            
            <Card>
                <Title icon={<FiUsers />}>Collaborators</Title>
                
                <p>The following users are authorized to make changes to this site.</p>
                
                <div className={styling.spinner} hidden={!isLoading}>
                    <Spinner invert />
                </div>
                
                <ul className={styling.list}>
                    {users.map((user, i) => (
                        <li className={styling.user} key={user.email} style={{ animationDelay: (i * 100) + 'ms' }}>
                            <Avatar avatarId={user.avatar} />
                            
                            <div className={styling.column} hidden={user.isPending || user.notFound}>
                                {user.firstName} {user.lastName}
                            </div>
                            
                            <div className={styling.column} hidden={!user.isPending}>
                                <Badge size='small' type='info'>pending</Badge>
                            </div>
                            
                            <div className={styling.column} hidden={!user.notFound}>
                                <Badge size='small' type='danger'>user cannot be added</Badge>
                            </div>
                            
                            <div className={styling.role}>
                                <Badge size='small' type='info'>{user.role || 'editor'}</Badge>
                            </div>
                            
                            <div className={styling.column}>{user.email}</div>
                            
                            <Button
                                size='smaller'
                                color='dangerLight'
                                onClick={() => removeUser(user.id)}
                                disabled={store.id === user.id || user.isPending || user.notFound || !isAdmin}
                            >
                                {store.id === user.id ? 'You' : 'Remove'}
                            </Button>
                        </li>
                    ))}
                </ul>
                
                <span className={styling.addUser} hidden={!isAdmin}>
                    <Title icon={<FiUserPlus />}>Add User</Title>
                    
                    <p>Adding users to this site will give them full control over the site.</p>
                    
                    <div className={styling.addWrapper}>
                        <InputField
                            name='email'
                            value={email}
                            placeholder='Email'
                            onChange={changeHandler}
                            size='small'
                            test={config.regex.email}
                        />
                        
                        <div className={styling.roleDropdown}>
                            <Dropdown
                                items={roles}
                                type='thin'
                                changeHandler={selectHandler}
                                selected={role}
                            />
                        </div>
                        
                        <Button size='smaller' onClick={inviteUser} isLoading={isInviting} disabled={!emailIsValid}>
                            Add
                        </Button>
                    </div>
                </span>
            </Card>
        </>
    );
};

export default UserManagement;