import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner } from 'components/UI/spinner/Spinner';
import Builder from 'components/builder/Builder';
import Title from 'components/UI/title/Title';

import { useStore } from 'context';
import client from 'client';

const Page = () => {
    // Store
    const [, , setError] = useStore();
    
    // Params
    const { pageId } = useParams();
    
    // State
    const [isLoading, setIsLoading] = useState(!!pageId);
    const [page, setPage] = useState({});
    
    
    /**
     * Fetches the page with the page ID from the URL
     * @type {(...args: any[]) => any}
     */
    const fetchPage = useCallback(async () => {
        try {
            setIsLoading(true);
            
            const page = await client('getPage', null, { pageId });
            
            setPage(page);
            setIsLoading(false);
            
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setError(error);
        }
    }, [pageId, setError]);
    
    
    useEffect(() => {
        if (pageId) {
            fetchPage();
        }
    }, [fetchPage, pageId]);
    
    // Determines the content
    const content = isLoading ? <LoadingSpinner /> : <Builder data={page} dataType='page' />;
    
    return (
        <>
            <Title title='Page' subtitle='Create or edit a page' />
            
            {content}
        </>
    );
};

export default Page;