import React, { useState } from 'react';

import InputField from 'components/UI/inputField/InputField';
import Button from 'components/UI/button/Button';
import Title from 'components/UI/title/Title';
import Modal from 'components/UI/modal/Modal';
import Badge from 'components/UI/badge/Badge';

import { useStore } from 'context';
import client from 'client';
import utils from 'utils';

import styling from './Sites.module.scss';

/**
 * Site card component
 * @param index {number} index of the site
 * @param id {string} ID of the site
 * @param name {string} name of the site
 * @param description {string} description of the site
 * @param clickHandler {function} selection handler
 * @param updatedAt {number} timestamp of when the site was updated
 * @param isSelected {boolean} determines if the current page is selected
 * @returns {*}
 * @constructor
 */
const Site = ({ index, id, name, description, avatar, clickHandler, createdAt, isSelected }) => (
    <div className={styling.site} style={{ animationDelay: (index * 120) + 'ms' }}>
        <div className={styling.header}>
            <Badge type='info'>ID: {id}</Badge>

            <div className={styling.settings} onClick={() => clickHandler(id, true)}>
                <span />
                <span />
                <span />
            </div>
        </div>

        <div className={styling.meta}>
            <div className={styling.avatar}>
                {(avatar) ? <img src={avatar} alt='site avatar' /> : name?.slice(0, 2)}
            </div>

            <div>
                <h3>{name}</h3>

                <p>{description}</p>
            </div>
        </div>

        <span>Created on {utils.getDate(createdAt)}</span>

        <div className={styling.controls}>
            <Button size='smaller' onClick={() => clickHandler(id)} disabled={isSelected}>
                {isSelected ? 'Selected' : 'Select'}
            </Button>
        </div>
    </div>
);

/**
 * Renders all available sites.
 * @returns {*}
 * @constructor
 */
const Sites = ({ history }) => {
    const [store, dispatch, setError] = useStore();
    const [{ name, description, modalOpen, isLoading }, setState] = useState({
        name: '',
        description: '',
        modalOpen: false,
        isLoading: false
    });


    /**
     * Handles input field changes.
     * @param name {string} name of the input field
     * @param value {string} value that was typed into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };


    /**
     * Toggles the modal visibility.
     */
    const toggleModal = () => {
        setState(prevState => ({ ...prevState, modalOpen: !prevState.modalOpen }));
    };


    /**
     * Creates a new site.
     */
    const createSite = async () => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));

            const basicData = await client('createSite', { name, description });

            dispatch({ type: 'update', payload: basicData });
            setState(prevState => ({ ...prevState, name: '', description: '', modalOpen: false, isLoading: false }));

        } catch (error) {
            console.error(error);
            setState(prevState => ({ ...prevState, modalOpen: false, isLoading: false }));
            setError(error);
        }
    };


    /**
     * Sets the site with the given ID as currently active site.
     * @param id {string} ID of the selected site
     * @param openSettings {boolean} determines if the user should be navigated to the settings
     */
    const selectHandler = (id, openSettings) => {
        const desiredSite = store.sites.find(x => x.id === id);
        dispatch({ type: 'setCurrentSite', payload: desiredSite });

        if (openSettings) {
            history.push('/' + id + '/settings');
        }
    };


    // Determines how many sites the user owns
    const ownedSites = (store.sites || []).filter(x => x.role === 'owner').length;


    return (
        <>
            <Title
                title='All Sites'
                subtitle='Create a new website'
            />

            <div className={styling.create}>
                <Button onClick={toggleModal} disabled={ownedSites >= 5}>Add Site</Button>
            </div>

            <section className={styling.wrapper}>
                {(store?.sites || []).map((site, i) => (
                    <Site
                        id={site.id}
                        index={i}
                        key={site.id}
                        name={site.name}
                        avatar={site.avatar}
                        description={site.description}
                        clickHandler={selectHandler}
                        isSelected={store?.currentSite?.id === site.id}
                        createdAt={site.createdAt}
                    />
                ))}
            </section>

            <Modal open={modalOpen}>
                <h3 className={styling.title}>Add new site</h3>

                <div className={styling.row}>
                    <div className={styling.column}>
                        <h6>Site name</h6>
                        <InputField
                            name='name'
                            placeholder='Site name'
                            value={name}
                            onChange={changeHandler}
                        />
                    </div>

                    <div className={styling.column}>
                        <h6>Site description</h6>
                        <InputField
                            name='description'
                            placeholder='Site description'
                            value={description}
                            onChange={changeHandler}
                        />
                    </div>
                </div>

                <div className={styling.controls}>
                    <Button
                        size='smaller'
                        color='light'
                        onClick={toggleModal}
                    >
                        Cancel
                    </Button>

                    <Button
                        size='smaller'
                        onClick={createSite}
                        isLoading={isLoading}
                        disabled={!name || !description}
                    >
                        Create
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default Sites;