import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';

import Confirmation from 'components/UI/confirmation/Confirmation';
import DangerZone from 'components/UI/dangerZone/DangerZone';
import InputField from 'components/UI/inputField/InputField';
import Button from 'components/UI/button/Button';
import Title from 'components/UI/title/Title';
import Card from 'components/UI/card/Card';

import { useStore } from 'context';
import client from 'client';

import styling from './SiteSettings.module.scss';

const SiteSettings = ({ history }) => {
    // Store
    const [store, dispatch, setError] = useStore();
    
    // State
    const [state, setState] = useState({
        name: '',
        description: '',
        base64: '',
        modalOpen: false,
        isLoading: false
    });
    
    const { name, description, base64, modalOpen, isLoading } = state;
    
    // Params
    const { currentSite } = useParams();
    
    
    /**
     * Toggles the confirmation modal.
     */
    const toggleDeleteConfirmation = () => {
        setState(prevState => ({ ...prevState, modalOpen: !prevState.modalOpen }));
    };
    
    
    /**
     * Handles input field changes.
     * @param name {string} name of the input field
     * @param value {string} value that was entered into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };
    
    
    /**
     * Updates the site's data.
     * @returns {Promise<void>}
     */
    const updateSiteData = async () => {
        try {
            dispatch({ type: 'updateCurrentSite', payload: { avatar: base64, name, description } });
            
            await client('updateSite', { avatar: base64 || '-', name, description }, { siteId: currentSite });
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };
    
    
    /**
     * Converts a file to a base64 string.
     * @param file {Blob} the image that should be converted
     * @returns {Promise<string>} the base64 string of the provided image
     */
    const convertToBase64 = (file) => new Promise(async (resolve, reject) => {
        const config = {
            maxWidth: 200,
            maxHeight: 200,
            quality: 0.8
        };
        
        const resized = await window.BrowserImageResizer.readAndCompressImage(file, config);
        
        const reader = new FileReader();
        
        reader.readAsDataURL(resized);
        
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
    
    
    /**
     * Opens the file select dialog and sets the selected
     * image as avatar for the current site.
     */
    const selectAvatar = () => {
        const input = document.createElement('input');
        
        input.type = 'file';
        input.accept = 'image/*';
        
        input.onchange = async () => {
            try {
                const base64String = await convertToBase64(input.files[0]);
                
                if (base64String.length > 28000) {
                    setError('The file is too large');
                    return;
                }
                
                setState(prevState => ({ ...prevState, base64: base64String }));
                
                input.files = null;
                
            } catch (error) {
                console.error(error);
                setError(error);
            }
        };
        
        input.click();
    };
    
    
    /**
     * Resets the current's site avatar.
     * @returns {Promise<void>}
     */
    const resetAvatar = async () => {
        try {
            setState(prevState => ({ ...prevState, base64: '' }));
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };
    
    
    /**
     * Deletes the currently selected site.
     * @returns {Promise<void>}
     */
    const deleteHandler = async () => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));
            
            await client('deleteSite', null, { siteId: currentSite });
            
            const updatedSites = store?.sites?.filter(x => x.id !== currentSite) || [];
            dispatch({ type: 'update', payload: { sites: updatedSites, currentSite: {} } });
            
            history.push('/sites');
            
        } catch (error) {
            console.error(error);
            toggleDeleteConfirmation();
            setError(error);
            setState(prevState => ({ ...prevState, isLoading: false }));
        }
    };
    
    
    /**
     * Saves the site's data to the local state.
     */
    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            name: store?.currentSite?.name || '',
            description: store?.currentSite?.description || '',
            base64: store?.currentSite?.avatar || ''
        }));
    }, [store]);
    
    
    // Determines if data was changed
    const prevName = store?.currentSite?.name;
    const prevDesc = store?.currentSite?.description;
    const prevAvatar = store?.currentSite?.avatar;
    
    const wasUpdated = prevName !== name || prevDesc !== description || prevAvatar !== base64;
    
    
    return (
        <>
            <Title title='Site Settings' subtitle='Manage your site settings' />
            
            <div className={styling.container}>
                <Card>
                    <div className={styling.flexRow}>
                        <div className={styling.wrapper}>
                            <div className={styling.avatar}>
                                <img src={base64} alt='site logo' hidden={!base64} />
                                
                                <div className={styling.delete} onClick={resetAvatar}>
                                    <FiTrash2 />
                                </div>
                            </div>
                            
                            <div>
                                <h6>Avatar</h6>
                                <p>The site's avatar (quadratic images work best)</p>
                            </div>
                        </div>
                        
                        <Button size='smaller' onClick={selectAvatar} color='light'>Change</Button>
                    </div>
                    
                    <div className={styling.row}>
                        <h6>Name</h6>
                        
                        <InputField
                            name='name'
                            placeholder='Name'
                            value={name}
                            onChange={changeHandler}
                        />
                    </div>
                    
                    <div className={styling.row}>
                        <h6>Description</h6>
                        
                        <InputField
                            name='description'
                            placeholder='Description'
                            value={description}
                            onChange={changeHandler}
                        />
                    </div>
                    
                    <div className={styling.controls}>
                        <Button size='smaller' onClick={updateSiteData} disabled={!wasUpdated}>Update</Button>
                    </div>
                    
                    <DangerZone />
                    
                    <div className={styling.flexRow}>
                        <div>
                            <h6>Delete Site</h6>
                            <p>Delete this site and all its data</p>
                        </div>
                        
                        <Button size='smaller' onClick={toggleDeleteConfirmation} color='dangerLight'>Delete</Button>
                    </div>
                </Card>
            </div>
            
            <Confirmation
                open={modalOpen}
                title='Delete site'
                isLoading={isLoading}
                label='Delete'
                message='Please confirm that you want to delete this site and all its data. This is permanent and cannot be undone.'
                cancelHandler={toggleDeleteConfirmation}
                confirmHandler={deleteHandler}
            />
        </>
    );
};

export default SiteSettings;