import React, { useCallback, useEffect, useState } from 'react';
import { FiAnchor, FiTrash2 } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import copy from 'fast-copy';

import InputField from 'components/UI/inputField/InputField';
import Checkbox from 'components/UI/checkbox/Checkbox';
import Card, { Title } from 'components/UI/card/Card';
import MainTitle from 'components/UI/title/Title';
import Button from 'components/UI/button/Button';
import Badge from 'components/UI/badge/Badge';
import Modal from 'components/UI/modal/Modal';
import DevTo from './devTo/DevTo';

import { useStore } from 'context';
import client from 'client';

import netlifyLogo from 'assets/images/logos/netlify.svg';
import firebaseLogo from 'assets/images/logos/firebase.svg';
import devLogo from 'assets/images/logos/devto.svg';

import styling from './Integrations.module.scss';

// Links
const baseAPIUrlNetlify = 'https://api.caasy.io/webhooks/netlify-notifications/';
const baseAPIUrlFirebase = 'https://api.caasy.io/webhooks/firebase-notifications/';
const netlifyDocs = 'https://docs.netlify.com/site-deploys/notifications/#outgoing-webhooks';

// Logos
const NetlifyLogo = () => <img src={netlifyLogo} className={styling.logo} alt='netlify' />;
const FirebaseLogo = () => <img src={firebaseLogo} className={styling.logo} alt='firebase' />
const DEVLogo = () => <img src={devLogo} className={styling.logo} alt='dev' />

/**
 * Renders a webhook.
 * @param webhook {object} a webhook
 * @param index {number} index of the webhook
 * @param checkHandler {function} handles checkbox changes
 * @param deleteHandler {function} deletes a webhook
 * @returns {*}
 * @constructor
 */
const Webhook = ({ webhook, index, checkHandler, deleteHandler }) => (
    <div className={styling.webhook} style={{ animationDelay: index * 120 + 'ms' }}>
        <div className={styling.webhookLabel}>WEBHOOK</div>
        <div className={styling.webhookTitle}>
            <div>
                <h6>{webhook.name}</h6>
                <Badge size='tiny' type='info'>POST</Badge>
            </div>
            
            <div className={styling.webhookDelete} onClick={() => deleteHandler(index)}>
                <FiTrash2 />
            </div>
        </div>
        
        <p className={styling.url}>{webhook.url}</p>
        
        <div className={styling.events}>
            <div className={styling.event}>
                <Checkbox
                    checked={webhook.onPostCreated}
                    id='onPostCreated'
                    changeHandler={() => checkHandler(index, 'onPostCreated')}
                />
                <span>on post created</span>
            </div>
            
            <div className={styling.event}>
                <Checkbox
                    checked={webhook.onPostPublished}
                    id='onPostPublished'
                    changeHandler={() => checkHandler(index, 'onPostPublished')}
                />
                <span>on post published</span>
            </div>
            
            <div className={styling.event}>
                <Checkbox
                    checked={webhook.onPostUnpublished}
                    id='onPostUnpublished'
                    changeHandler={() => checkHandler(index, 'onPostUnpublished')}
                />
                <span>on post unpublished</span>
            </div>
            
            <div className={styling.event}>
                <Checkbox
                    checked={webhook.onPostUpdated}
                    id='onPostUpdated'
                    changeHandler={() => checkHandler(index, 'onPostUpdated')}
                />
                <span>on post updated</span>
            </div>
            
            <div className={styling.event}>
                <Checkbox
                    checked={webhook.onPostDeleted}
                    id='onPostDeleted'
                    changeHandler={() => checkHandler(index, 'onPostDeleted')}
                />
                <span>on post deleted</span>
            </div>
            
            <div className={styling.event}>
                <Checkbox
                    checked={webhook.onPageCreated}
                    id='onPageCreated'
                    changeHandler={() => checkHandler(index, 'onPageCreated')}
                />
                <span>on page created</span>
            </div>
            
            <div className={styling.event}>
                <Checkbox
                    checked={webhook.onPagePublished}
                    id='onPagePublished'
                    changeHandler={() => checkHandler(index, 'onPagePublished')}
                />
                <span>on page published</span>
            </div>
            
            <div className={styling.event}>
                <Checkbox
                    checked={webhook.onPageUnpublished}
                    id='onPageUnpublished'
                    changeHandler={() => checkHandler(index, 'onPageUnpublished')}
                />
                <span>on page unpublished</span>
            </div>
            
            <div className={styling.event}>
                <Checkbox
                    checked={webhook.onPageUpdated}
                    id='onPageUpdated'
                    changeHandler={() => checkHandler(index, 'onPageUpdated')}
                />
                <span>on page updated</span>
            </div>
            
            <div className={styling.event}>
                <Checkbox
                    checked={webhook.onPageDeleted}
                    id='onPageDeleted'
                    changeHandler={() => checkHandler(index, 'onPageDeleted')}
                />
                <span>on page deleted</span>
            </div>
        </div>
    </div>
);


/**
 * Renders all integrations for the currently active site.
 * @returns {*}
 * @constructor
 */
const Integrations = () => {
    // Store
    const [store, dispatch, setError] = useStore();
    
    // State
    const [state, setState] = useState({
        addWebhookModalOpen: false,
        newWebhookName: '',
        newWebhookUrl: '',
        webhooks: [],
        hasFetched: false,
        importDEVArticlesActive: false
    });
    
    const { addWebhookModalOpen, newWebhookName, newWebhookUrl, webhooks, hasFetched, importDEVArticlesActive } = state;
    
    // Params
    const { currentSite } = useParams();
    
    /**
     * Handles changes of webhooks checkboxes.
     * @param index {number} index of the webhook
     * @param event {string} checkbox of the event that was changed
     */
    const checkboxHandler = async (index, event) => {
        try {
            const tmpWebhooks = copy(webhooks);
            tmpWebhooks[index][event] = !tmpWebhooks[index][event];
            setState(prevState => ({ ...prevState, webhooks: tmpWebhooks }));
            
            await client('updateSite', { webhooks: tmpWebhooks }, { siteId: currentSite });
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };
    
    
    /**
     * Handles input field changes.
     * @param name {string} name of the input field that was changed
     * @param value {string} the text that was entered into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };
    
    
    /**
     * Deletes a webhook.
     * @param index {number} index of the webhook that should be deleted
     */
    const deleteWebhookHandler = async (index) => {
        try {
            const tmpWebhooks = copy(webhooks);
            tmpWebhooks.splice(index, 1);
            setState(prevState => ({ ...prevState, webhooks: tmpWebhooks }));
            
            await client('updateSite', { webhooks: tmpWebhooks }, { siteId: currentSite });
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };
    
    
    /**
     * Toggles the add webhook modal visibility.
     */
    const toggleAddWebhookModal = () => {
        setState(prevState => ({ ...prevState, addWebhookModalOpen: !prevState.addWebhookModalOpen }));
    };
    
    
    const toggleDEVImportVisibility = () => {
        setState(prevState => ({...prevState, importDEVArticlesActive: !prevState.importDEVArticlesActive }));
    };
    
    
    /**
     * Creates a new webhook.
     */
    const createNewWebhook = async () => {
        try {
            const tmpWebhooks = copy(webhooks);
            
            tmpWebhooks.push({
                name: newWebhookName,
                url: newWebhookUrl,
                onPostCreated: false,
                onPostPublished: false,
                onPostUnpublished: false,
                onPostUpdated: false,
                onPostDeleted: false,
                onPageCreated: false,
                onPagePublished: false,
                onPageUnpublished: false,
                onPageUpdated: false,
                onPageDeleted: false
            });
            
            setState(prevState => ({
                ...prevState,
                webhooks: tmpWebhooks,
                addWebhookModalOpen: false,
                newWebhookName: '',
                newWebhookUrl: ''
            }));
            
            await client('updateSite', { webhooks: tmpWebhooks }, { siteId: currentSite });
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };
    
    
    /**
     * Updates the global state so that the latest
     * changes are reflected in the global state.
     * @returns {Promise<void>}
     */
    const updateGlobalData = useCallback(async () => {
        try {
            const data = await client('getUser');
            
            setState(prevState => ({ ...prevState, hasFetched: true }));
            dispatch({ type: 'update', payload: data });
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }, [dispatch, setError]);
    
    
    /**
     * Loads the currently selected site in the local state.
     * It invokes a global state update when the component
     * is being unmounted.
     */
    useEffect(() => {
        if (!hasFetched) {
            updateGlobalData();
        }
        
        const activeSite = store?.sites?.find(x => x.id === currentSite) || {};
        setState(prevState => ({ ...prevState, webhooks: activeSite.webhooks || [] }));
    }, [store, hasFetched, currentSite, updateGlobalData]);
    
    
    return (
        <>
            <MainTitle title='Integrations' subtitle='Integrate with your favorite tools' />
            
            <div className={styling.container}>
                <Card>
                    <Title icon={<DEVLogo />}>Import Articles from DEV</Title>
                    
                    <p className={styling.description}>
                        You can import your published articles from DEV. Click the button below to start the import
                        process. You are not required to provide an API key. Please only import your own articles.
                    </p>
                    
                    <div className={styling.controls}>
                        <Button size='smaller' onClick={toggleDEVImportVisibility}>Import</Button>
                    </div>
                </Card>
                
                <Card>
                    <Title icon={<NetlifyLogo />}>Netlify Notifications</Title>
                    
                    <p className={styling.description}>
                        You can easily link a site in Caasy with a website on Netlify. This will feed your <Link
                        to={'/' + currentSite + '/activity'}>Activity Stream</Link> with deploy information from
                        Netlify. If you are looking for a way to trigger Netlify builds at certain events in Caasy,
                        please use the webhook section below.
                    </p>
                    
                    <div className={styling.webhookId}>
                        {baseAPIUrlNetlify + currentSite + '?id=' + store?.currentSite?.webhookNotificationId}
                    </div>
                    
                    <p>
                        Set up <a href={netlifyDocs} target='_blank' rel='noreferrer noopener'>notifications on
                        Netlify</a> and use the URL above as "URL to notify".
                    </p>
                </Card>
                
                
                <Card>
                    <Title icon={<FirebaseLogo />}>Firebase Notifications</Title>
                    
                    <p className={styling.description}>
                        Add status updates from Firebase deployments to your <Link to={'/' + currentSite + '/activity'}>Activity
                        Stream</Link> by adding pre and post deployment hooks to your build flow. If you are looking for
                        a way to trigger a deployment, please use the webhook section below.
                    </p>
                    
                    <div className={styling.webhookId}>
                        {baseAPIUrlFirebase + currentSite + '?id=' + store?.currentSite?.webhookNotificationId}
                    </div>
                    
                    <p>
                        Learn how to set up Firebase hooks in our <a
                        href='https://docs.caasy.io/docs/webhooks#firebase-notifications' target='_blank'
                        rel='noreferrer noopener'>documentation</a>.
                    </p>
                </Card>
                
                <Card>
                    <Title icon={<FiAnchor />}>Webhooks</Title>
                    
                    <p className={styling.description}>
                        Webhooks are are great way to trigger actions in other systems. You can use them to trigger
                        build flows at providers such as Github and Netlify. Please refer to our documentation for more
                        information.
                    </p>
                    
                    <div className={styling.webhooks}>
                        {webhooks.map((webhook, i) => (
                            <Webhook
                                webhook={webhook}
                                key={i}
                                index={i}
                                checkHandler={checkboxHandler}
                                deleteHandler={deleteWebhookHandler}
                            />
                        ))}
                    </div>
                    
                    <div className={styling.controls}>
                        <Button size='smaller' onClick={toggleAddWebhookModal} hidden={webhooks.length >= 5}>Add</Button>
                    </div>
                </Card>
            </div>
            
            <Modal open={addWebhookModalOpen}>
                <h3 className={styling.modalTitle}>Add Webhook</h3>
                
                <div className={styling.row}>
                    <h6>Name</h6>
                    <InputField
                        name='newWebhookName'
                        placeholder='Name'
                        value={newWebhookName}
                        onChange={changeHandler}
                    />
                </div>
                
                <div className={styling.row}>
                    <h6>URL</h6>
                    <InputField
                        name='newWebhookUrl'
                        placeholder='Url'
                        value={newWebhookUrl}
                        onChange={changeHandler}
                    />
                </div>
                
                <div className={styling.controls}>
                    <Button size='smaller' color='light' onClick={toggleAddWebhookModal}>Cancel</Button>
                    <Button size='smaller' onClick={createNewWebhook}>Create</Button>
                </div>
            </Modal>
            
            <DevTo
                isVisible={importDEVArticlesActive}
                close={toggleDEVImportVisibility}
            />
        </>
    );
};

export default Integrations;