import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiFolder, FiImage, FiType, FiVideo } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import copy from 'fast-copy';

import { LoadingSpinner } from 'components/UI/spinner/Spinner';
import InfoBox from 'components/UI/infoBox/InfoBox';
import Button from 'components/UI/button/Button';
import Title from 'components/UI/title/Title';
import Panel from 'components/UI/panel/Panel';

import client, { upload } from 'client';
import { useStore } from 'context';
import config from 'config';
import etalon from 'etalon';
import utils from 'utils';

import styling from './MediaLibrary.module.scss';


// Available sizes
const availableSizes = ['1500', '1200', '800', '400'];

// Base URL
const imageThumbnailURL = config.connectivity.imageThumbnailBucket;


/**
 * Returns the icon for a given file type.
 * @param type {string} file type
 * @param fileName {string} the name of the file
 * @returns {*}
 */
const getIcon = (type, fileName) => {
    if (etalon.videoTypes.includes(type)) {
        return <FiVideo />;
    }
    
    if (etalon.documentTypes.includes(type)) {
        return <FiType />;
    }
    
    if (etalon.folderTypes.includes(type)) {
        return <FiFolder />;
    }
    
    if (etalon.imageTypes.includes(type) && !fileName) {
        return <FiImage />;
    }
    
    if (etalon.imageTypes.includes(type)) {
        return (
            <object data={`${imageThumbnailURL}400x400_${fileName}`} type={type || 'image/png'}>
                <FiImage />
            </object>
        );
    }
};


/**
 * Shows the file name, its icon or thumbnail and its size.
 * @param mediaItem {object} the media item this component should represent
 * @param isActive {boolean} determines if the media item is selected
 * @param selectHandler {function} is invoked when the item is clicked
 * @returns {*}
 * @constructor
 */
export const MediaItem = ({ mediaItem, isActive, selectHandler }) => (
    <motion.div className={styling[isActive ? 'active' : 'item']} onClick={selectHandler} positionTransition>
        <div className={styling.icon}>{getIcon(mediaItem.type, mediaItem.name)}</div>
        
        <p>{utils.removePrefix(mediaItem.name)}</p>
        
        <div className={styling.size}>{utils.byteFormatter(mediaItem.size)}</div>
    </motion.div>
);


/**
 * Shows all media items and allows users to add and remove them.
 * @returns {*}
 * @constructor
 */
const MediaLibrary = () => {
    // Store
    const [, , setError] = useStore();
    
    // State
    const [queuedMedia, setQueuedMedia] = useState([]);
    const [allMedia, setAllMedia] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    
    // Params
    const { currentSite } = useParams();
    
    // Refs
    const input = useRef({});
    const image = useRef({});
    
    // Image URLs
    const imageURL = config.connectivity.imageBucket;
    
    
    /**
     * Removes the item with the given ID from the queue.
     * @param id {string} the ID of the element that should be dequeued
     * @param res {object} response that contains the processed media item
     */
    const removeFromQueue = (id, res) => {
        setQueuedMedia(prevState => {
            const tmpQueue = copy(prevState);
            const targetIndex = tmpQueue.findIndex(x => x.id === id);
            
            if (targetIndex < 0) {
                return tmpQueue;
            }
            
            tmpQueue.splice(targetIndex, 1);
            
            return tmpQueue;
        });
        
        if (res.status === 200) {
            setAllMedia(prevState => [res.data, ...prevState]);
        }
    };
    
    
    /**
     * Marks the queued element with the given ID as failed.
     * @param id {string} ID of the queued media item that should be marked as failed
     * @param isTooLarge {boolean} determines if it failed because the file was too large
     */
    const handleFailure = (id, isTooLarge = false) => {
        setQueuedMedia(prevState => {
            const tmpQueue = copy(prevState);
            const index = tmpQueue.findIndex(x => x.id === id);
            
            if (index < 0) {
                return tmpQueue;
            }
            
            tmpQueue[index].state = 'failed';
            tmpQueue[index].message = isTooLarge ? 'too large' : 'failed';
            
            return tmpQueue;
        });
    };
    
    
    /**
     * Handles the upload of the selected files.
     * @param files {array} all selected files
     * @returns {Promise<void>}
     */
    const uploadFiles = async ({ target: { files } }) => {
        const availableQuota = 50 - (allMedia.length + queuedMedia.length);
        let counter = 0;
        
        for (let file of files) {
            if (++counter > availableQuota) {
                break;
            }
            
            const data = new FormData();
            const id = utils.random(15);
            
            const item = {
                id,
                name: file.name,
                type: file.type,
                progress: 0
            };
            
            data.append('file', file);
            
            const progressHandler = (e) => {
                const completed = Math.round((e.loaded * 100) / e.total);
                
                setQueuedMedia(prevState => {
                    const tmpQueue = copy(prevState);
                    const index = tmpQueue.findIndex(x => x.id === id);
                    
                    if (index < 0) {
                        return tmpQueue;
                    }
                    
                    if (completed === 100) {
                        tmpQueue[index].message = 'processing';
                    }
                    
                    tmpQueue[index].progress = completed;
                    
                    return tmpQueue;
                });
            };
            
            setQueuedMedia(prevState => [...prevState, item]);
            
            if (file.size > 20000000) {
                handleFailure(id, true);
                continue;
            }
            
            upload(currentSite, data, progressHandler)
                .then((newItem) => removeFromQueue(id, newItem))
                .catch(() => handleFailure(id));
        }
        
        input.current.value = '';
    };
    
    
    /**
     * Deletes a media item with the given ID.
     * @param mediaId {string} ID of the item that should be deleted
     * @returns {Promise<void>}
     */
    const deleteMediaItem = async (mediaId) => {
        try {
            const tmpAllMedia = copy(allMedia);
            const filteredMedia = tmpAllMedia.filter(x => x.id !== mediaId);
            
            setAllMedia(filteredMedia);
            setSelectedItem(null);
            
            await client('deleteMedia', null, { siteId: currentSite, mediaId });
            
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };
    
    
    /**
     * Shows the details for the item with the given ID
     * @param id {string} the ID of the selected item
     */
    const selectItem = (id) => {
        const backgroundColor = getComputedStyle(document.body).getPropertyValue('--color-2-darker');
        const selectedItem = allMedia.find(x => x.id === id);
        
        if (selectedItem && etalon.imageTypes.includes(selectedItem.type)) {
            image.current.style.backgroundImage = `url("${imageThumbnailURL}400x400_${selectedItem.name}"`
        } else {
            image.current.style.background = backgroundColor;
        }
        
        setSelectedItem(selectedItem);
    };
    
    
    /**
     * Copies a given value to the clipboard.
     * @param value {string} the value that should be copied to the clipboard
     */
    const toClipboard = (value) => {
        const dummyField = document.createElement('textarea');
        dummyField.innerHTML = value;
        document.body.appendChild(dummyField);
        dummyField.select();
        document.execCommand('copy');
        document.body.removeChild(dummyField);
    };
    
    
    /**
     * Opens the select file dialog.
     * @returns {*}
     */
    const selectFiles = () => input.current.click();
    
    
    /**
     * Fetches all media items
     * @type {(...args: any[]) => any}
     */
    const fetchAllMedia = useCallback(async () => {
        try {
            const mediaItems = await client('getAllMedia', null, { siteId: currentSite });
            
            setAllMedia(mediaItems);
            setIsLoading(false);
            
        } catch (error) {
            console.error(error);
            setError(error);
            setIsLoading(false);
        }
    }, [currentSite, setError]);
    
    
    /**
     * Fetch all media when the fetchAllMedia
     * function changes.
     */
    useEffect(() => {
        fetchAllMedia();
    }, [fetchAllMedia]);
    
    
    // Media library
    const content = (
        <div className={styling.wrapper}>
            <div className={styling.content}>
                <div className={styling.info}>
                    <InfoBox title='Media will be public' type='warning' closeableId='media'>
                        All media items will automatically be publicly available for everyone on the internet once they
                        are uploaded.
                    </InfoBox>
                </div>
                
                
                <div className={styling.upload}>
                    <Button size='smaller' onClick={selectFiles} disabled={allMedia.length >= 50}>Add Media</Button>
                </div>
                
                
                <h5 hidden={!queuedMedia.length}>Uploading ({queuedMedia.length})</h5>
                
                <section className={styling.items}>
                    {queuedMedia.map(media => (
                        <div key={media.id} className={media.state === 'failed' ? styling.failed : styling.item}>
                            <div className={styling.icon}>{getIcon(media.type, '')}</div>
                            
                            <p>{media.name}</p>
                            
                            <div className={styling.progress}>
                                <div style={{ width: media.progress + '%' }} />
                            </div>
                            
                            <p>{media.message || media.progress + '%'}</p>
                        </div>
                    ))}
                </section>
                
                
                <h5>All media items ({allMedia.length})</h5>
                
                <section className={styling.items}>
                    {allMedia.map(media => (
                        <MediaItem
                            key={media.id}
                            isActive={selectedItem?.id === media.id}
                            mediaItem={media}
                            selectHandler={() => selectItem(media.id)}
                        />
                    ))}
                </section>
                
                
                <input type='file' ref={input} onChange={uploadFiles} multiple hidden />
            </div>
            
            
            <Panel>
                <div className={styling.itemDetails} hidden={!selectedItem}>
                    <h3>About this file</h3>
                    
                    <div className={styling.previewImage} ref={image} />
                    
                    <h6>{utils.removePrefix(selectedItem?.name)}</h6>
                    
                    <p>Created: {utils.getDateWithTime(selectedItem?.updatedAt)}</p>
                    <p>Size: {utils.byteFormatter(selectedItem?.size)}</p>
                    
                    
                    <div className={styling.urlReference}>Original</div>
                    <div className={styling.urlWrapper}>
                        <div className={styling.url}>{imageURL + selectedItem?.name || ''}</div>
                        
                        <div
                            className={styling.copyButton}
                            onClick={() => toClipboard(imageURL + selectedItem?.name || '')}
                        >
                            Copy
                        </div>
                    </div>
                    
                    
                    {availableSizes.map((size) => (
                        <div key={size}>
                            <div className={styling.urlReference}>Width: {size}px</div>
                            <div className={styling.urlWrapper}>
                                <div className={styling.url}>{imageURL + selectedItem?.name || ''}</div>
                                
                                <div
                                    className={styling.copyButton}
                                    onClick={() => toClipboard(`${imageThumbnailURL}${size}x${size}_${selectedItem?.name}`)}
                                >
                                    Copy
                                </div>
                            </div>
                        </div>
                    ))}
                    
                    <div className={styling.delete}>
                        <Button size='smaller' color='danger'
                                onClick={() => deleteMediaItem(selectedItem?.id)}>Delete</Button>
                    </div>
                </div>
            </Panel>
        </div>
    );
    
    
    return (
        <>
            <Title title='Media Library' subtitle='Manage your media items' />
            
            {isLoading ? <LoadingSpinner /> : content}
        </>
    );
};

export default MediaLibrary;