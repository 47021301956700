import React from 'react';

import styling from './Title.module.scss';

const Title = ({ title, subtitle }) => (
    <>
        <h2>{title}</h2>
        <h5 className={styling.subtitle}>{subtitle}</h5>
    </>
);

export default Title;