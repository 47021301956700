import React, { useEffect, useState } from 'react';
import { FaMarkdown } from 'react-icons/fa';
import copy from 'fast-copy';

import InputField, { TextArea } from 'components/UI/inputField/InputField';
import Button from 'components/UI/button/Button';
import Badge from 'components/UI/badge/Badge';

import etalon from 'etalon';
import config from 'config';

import styling from '../../Builder.module.scss';

/**
 * Name of the type.
 * @type {string}
 */
export const MARKDOWN_TYPE = 'markdown';


/**
 * Basic Markdown model.
 * @type {{id: string, text: {[p: string]: string}, type: string, customId: string}}
 */
export const markdownModel = {
    id: '',
    type: MARKDOWN_TYPE,
    customId: '',
    text: {
        [etalon.locales.en_US]: ''
    }
};


/**
 * Markdown block element shown in the Post or Page overview.
 * @param text {string} Markdown text
 * @param status {string} the status of the element
 * @returns {*}
 * @constructor
 */
export const MarkdownBlock = ({ text, status }) => (
    <div className={styling.blockWrapper}>
        <div className={styling.icon}>
            <FaMarkdown />
        </div>
        
        <div className={styling.blockDetails}>
            <span className={styling.blockType}>MARKDOWN</span>
            
            <Badge size='tiny' type='neutral' hidden={!status}>{status}</Badge>
            
            <h6>Markdown</h6>
            
            <p className={styling.blockCaption}>{text[etalon.locales.en_US]}</p>
        </div>
    </div>
);


/**
 * The icon shown in the list of all elements.
 * @param selectHandler {function} must be invoked when the element is selected
 * @returns {*}
 * @constructor
 */
export const MarkdownIcon = ({ selectHandler }) => (
    <div className={styling.elementIcon} onClick={() => selectHandler(MARKDOWN_TYPE)}>
        <div>
            <FaMarkdown />
        </div>
        
        <h6>Markdown</h6>
        
        <p>Markdown text element</p>
    </div>
);


/**
 * The settings for the Markdown element.
 * @param props {object} component props
 * @returns {*}
 * @constructor
 */
export const MarkdownSettings = (props) => {
    const [{ id, customId, text }, setState] = useState(markdownModel);
    
    
    /**
     * Handles input field changes.
     * @param target {object} the DOM node that has changed
     */
    const changeHandler = ({ target }) => {
        setState(prevState => {
            const locale = target.getAttribute('data-caasy-locale');
            
            prevState = copy(prevState);
            
            if (locale) {
                prevState[target.name][etalon.locales.en_US] = target.value;
            } else {
                prevState[target.name] = target.value;
            }
            
            return prevState;
        });
    };
    
    
    /**
     * Saves the edited settings.
     */
    const save = () => {
        const payload = { ...markdownModel, id, customId, text };
        props.saveSettings(payload);
    };
    
    
    /**
     * Sets the provided data to the component's state.
     */
    useEffect(() => {
        const { id, customId, text } = props;
        setState(prevState => ({ ...prevState, id, customId, text }));
    }, [props]);
    
    
    return (
        <>
            <div className={styling.header}>
                <h3>Markdown</h3>
                <Badge size='tiny' type='info'>ID: {id}</Badge>
            </div>
            
            <div className={styling.row}>
                <div className={styling.fullWidthColumn}>
                    <div className={styling.textArea}>
                        <TextArea
                            name='text'
                            placeholder='Enter markdown here...'
                            data-caasy-locale={etalon.locales.en_US}
                            value={text[etalon.locales.en_US]}
                            onChange={changeHandler}
                            size='small'
                            test={config.regex.maxLength(etalon.maxLength.text)}
                        />
                    </div>
                </div>
            </div>
            
            
            <div hidden={props.isEditor}>
                <div className={styling.devZone} />
                <div className={styling.row}>
                    <div className={styling.column}>
                        <h6>Custom ID</h6>
                        <InputField
                            name='customId'
                            placeholder='Custom Element ID'
                            value={customId}
                            onChange={changeHandler}
                            size='small'
                            test={config.regex.maxLength(etalon.maxLength.id)}
                        />
                    </div>
                </div>
            </div>
            
            
            <div className={styling.controls}>
                <Button size='smaller' color='light' onClick={props.closeSettings}>Cancel</Button>
                <Button size='smaller' onClick={save}>Save</Button>
            </div>
        </>
    );
};