import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiFile } from 'react-icons/fi';
import copy from 'fast-copy';

import { MediaItem } from 'components/mediaLibrary/MediaLibrary';
import InputField from 'components/UI/inputField/InputField';
import Spinner from 'components/UI/spinner/Spinner';
import Button from 'components/UI/button/Button';
import Badge from 'components/UI/badge/Badge';

import { useStore } from 'context';
import client from 'client';
import etalon from 'etalon';
import utils from 'utils';

import styling from '../../Builder.module.scss';

/**
 * Name of the type.
 * @type {string}
 */
export const FILE_TYPE = 'file';


/**
 * Basic file model.
 * @type {{fileName: string, externalFileUrl: string, id: string, label: {[p: string]: string}, type: string, customId: string}}
 */
export const fileModel = {
    id: '',
    type: FILE_TYPE,
    customId: '',
    label: {
        [etalon.locales.en_US]: ''
    },
    fileName: '',
    externalFileUrl: ''
};


/**
 * File block element shown in the post or page overview.
 * @param fileName {string} name of the selected file
 * @param status {string} status of the element
 * @returns {*}
 * @constructor
 */
export const FileBlock = ({ fileName, status }) => (
    <div className={styling.blockWrapper}>
        <div className={styling.icon}>
            <FiFile />
        </div>
        
        <div className={styling.blockDetails}>
            <span className={styling.blockType}>IMAGE</span>
            
            <Badge size='tiny' type='neutral' hidden={!status}>{status}</Badge>
            
            <h6>{utils.removePrefix(fileName) || '-'}</h6>
        </div>
    </div>
);


/**
 * The icon is shown in the list of all elements.
 * @param selectHandler {function} must be invoked when the the element is selected
 * @returns {*}
 * @constructor
 */
export const FileIcon = ({ selectHandler }) => (
    <div className={styling.elementIcon} onClick={() => selectHandler(FILE_TYPE)}>
        <div>
            <FiFile />
        </div>
        
        <h6>File</h6>
        
        <p>a single file element</p>
    </div>
);


/**
 * The settings for the file element.
 * @param props {object} the component props
 * @returns {*}
 * @constructor
 */
export const FileSettings = (props) => {
    // Store
    const [, , setError] = useStore();
    
    // State
    const [{ id, customId, label, fileName, externalFileUrl }, setElementData] = useState(fileModel);
    const [isLoading, setIsLoading] = useState(false);
    const [availableFiles, setAvailableFiles] = useState([]);
    
    // Params
    const { currentSite } = useParams();
    
    
    /**
     * Handles input field changes.
     * @param target {object} DOM node of the changed element
     */
    const changeHandler = ({ target }) => {
        const locale = target.getAttribute('data-caasy-locale') || '';
        
        setElementData(prevState => {
            prevState = copy(prevState);
            
            if (locale) {
                prevState[target.name][locale] = target.value;
            } else {
                prevState[target.name] = target.value;
            }
            
            return prevState;
        });
    };
    
    
    /**
     * Sets the file name of the selected file in the state.
     * @param fileName {string} the name of the selected file
     */
    const fileSelectHandler = (fileName) => {
        setElementData(prevState => ({ ...prevState, fileName }));
    };
    
    
    /**
     * Saves the edited settings.
     */
    const save = () => {
        const payload = { ...fileModel, id, customId, label, fileName, externalFileUrl };
        props.saveSettings(payload);
    };
    
    
    /**
     * Fetches all media items.
     * @type {(...args: any[]) => any}
     */
    const fetchMedia = useCallback(async () => {
        try {
            const allMedia = await client('getAllMedia', null, { siteId: currentSite });
            
            setAvailableFiles(allMedia);
            setIsLoading(false);
            
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setError(error);
        }
    }, [currentSite, setError]);
    
    
    /**
     * Makes a local copy of the element's keys coming from props.
     */
    useEffect(() => {
        const { id, customId, label, fileName, externalFileUrl } = props;
        setElementData(prevState => ({ ...prevState, id, customId, label, fileName, externalFileUrl }));
    }, [props]);
    
    
    /**
     * Fetches all media items.
     */
    useEffect(() => {
        fetchMedia();
    }, [fetchMedia]);
    
    
    return (
        <>
            <div className={styling.header}>
                <h3>File Settings</h3>
                <Badge size='tiny' type='info'>ID: {id}</Badge>
            </div>
            
            <div className={styling.row}>
                <div className={styling.fullWidthColumn}>
                    <h6>Available Files</h6>
                    <div className={styling.mediaItems}>
                        <div className={styling.grid} hidden={isLoading}>
                            {availableFiles.map(mediaItem => (
                                <MediaItem
                                    key={mediaItem.id}
                                    mediaItem={mediaItem}
                                    selectHandler={() => fileSelectHandler(mediaItem.name)}
                                    isActive={mediaItem.name === fileName}
                                />
                            ))}
                        </div>
                    </div>
                    
                    <div className={styling.spinner} hidden={!isLoading}>
                        <Spinner invert />
                    </div>
                </div>
            </div>
            
            <div className={styling.row}>
                <div className={styling.column}>
                    <h6>External File Url</h6>
                    <InputField
                        name='externalFileUrl'
                        placeholder='External File Url'
                        value={externalFileUrl}
                        onChange={changeHandler}
                        size='small'
                    />
                </div>
                
                <div className={styling.column}>
                    <h6>Label</h6>
                    <InputField
                        name='label'
                        data-caasy-locale={etalon.locales.en_US}
                        placeholder='Label'
                        value={label[etalon.locales.en_US]}
                        onChange={changeHandler}
                        size='small'
                    />
                </div>
            </div>
            
            <div hidden={props.isEditor}>
                <div className={styling.devZone} />
                <div className={styling.row}>
                    <div className={styling.singleColumn}>
                        <h6>Custom ID</h6>
                        <InputField
                            name='customId'
                            placeholder='Custom ID'
                            value={customId}
                            onChange={changeHandler}
                            size='small'
                        />
                    </div>
                </div>
            </div>
            
            <div className={styling.controls}>
                <Button size='smaller' color='light' onClick={props.closeSettings}>Cancel</Button>
                <Button size='smaller' onClick={save}>Save</Button>
            </div>
        </>
    );
};