import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner } from 'components/UI/spinner/Spinner';
import Builder from 'components/builder/Builder';
import Title from 'components/UI/title/Title';

import { useStore } from 'context';
import client from 'client';

const Post = () => {
    // Store
    const [, , setError] = useStore();

    // Params
    const { postId } = useParams();

    // State
    const [isLoading, setIsLoading] = useState(!!postId);
    const [post, setPost] = useState({});


    /**
     * Fetches the post with the post ID from the url
     * @type {(...args: any[]) => any}
     */
    const fetchPost = useCallback(async () => {
        try {
            setIsLoading(true);

            const post = await client('getPost', null, { postId });

            setPost(post);
            setIsLoading(false);

        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setError(error);
        }
    }, [postId, setError]);


    useEffect(() => {
        if (postId) {
            fetchPost();
        }
    }, [postId, fetchPost]);


    // Determines the content
    const content = isLoading ? <LoadingSpinner /> : <Builder data={post} dataType='post' />;

    return (
        <>
            <Title title='Blog Post' subtitle='Create or edit a blog post' />

            {content}
        </>
    );
};

export default Post;