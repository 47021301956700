export default {
    transition: {
        type: 'spring',
        damping: 25,
        stiffness: 400
    },
    documentTypes: ['application/pdf', 'text/plain', 'application/doc', 'application/docx', 'application/md'],
    imageTypes: ['image/png', 'image/jpg', 'image/jpeg', 'image/svg'],
    videoTypes: ['video/mp4', 'video/mov', 'video/mpg'],
    folderTypes: ['application/zip'],
    maxLength: {
        id: 200,
        generic: 50,
        text: 50000
    },
    locales: {
        af: 'af',
        sq: 'sq',
        am: 'am',
        ar_DZ: 'ar_DZ',
        ar_BH: 'ar_BH',
        ar_EG: 'ar_EG',
        ar_IQ: 'ar_IQ',
        ar_JO: 'ar_JO',
        ar_KW: 'ar_KW',
        ar_LB: 'ar_LB',
        ar_LY: 'ar_LY',
        ar_MA: 'ar_MA',
        ar_OM: 'ar_OM',
        ar_QA: 'ar_QA',
        ar_SA: 'ar_SA',
        ar_SY: 'ar_SY',
        ar_TN: 'ar_TN',
        ar_AE: 'ar_AE',
        ar_YE: 'ar_YE',
        hy: 'hy',
        as: 'as',
        az_AZ: 'az_AZ',
        eu: 'eu',
        be: 'be',
        bn: 'bn',
        bs: 'bs',
        bg: 'bg',
        my: 'my',
        ca: 'ca',
        zh_CN: 'zh_CN',
        zh_HK: 'zh_HK',
        zh_MO: 'zh_MO',
        zh_SG: 'zh_SG',
        zh_TW: 'zh_TW',
        hr: 'hr',
        cs: 'cs',
        da: 'da',
        dv: 'dv',
        nl_BE: 'nl_BE',
        nl_NL: 'nl_NL',
        en_AU: 'en_AU',
        en_BZ: 'en_BZ',
        en_CA: 'en_CA',
        en_CB: 'en_CB',
        en_GB: 'en_GB',
        en_IN: 'en_IN',
        en_IE: 'en_IE',
        en_JM: 'en_JM',
        en_NZ: 'en_NZ',
        en_PH: 'en_PH',
        en_ZA: 'en_ZA',
        en_TT: 'en_TT',
        en_US: 'en_US',
        et: 'et',
        fo: 'fo',
        fa: 'fa',
        fi: 'fi',
        fr_BE: 'fr_BE',
        fr_CA: 'fr_CA',
        fr_FR: 'fr_FR',
        fr_LU: 'fr_LU',
        fr_CH: 'fr_CH',
        mk: 'mk',
        gd_IE: 'gd_IE',
        gd: 'gd',
        de_AT: 'de_AT',
        de_DE: 'de_DE',
        de_LI: 'de_LI',
        de_LU: 'de_LU',
        de_CH: 'de_CH',
        el: 'el',
        gn: 'gn',
        gu: 'gu',
        he: 'he',
        hi: 'hi',
        hu: 'hu',
        is: 'is',
        id: 'id',
        it_IT: 'it_IT',
        it_CH: 'it_CH',
        ja: 'ja',
        kn: 'kn',
        ks: 'ks',
        kk: 'kk',
        km: 'km',
        ko: 'ko',
        lo: 'lo',
        la: 'la',
        lv: 'lv',
        lt: 'lt',
        ms_BN: 'ms_BN',
        ms_MY: 'ms_MY',
        ml: 'ml',
        mt: 'mt',
        mi: 'mi',
        mr: 'mr',
        mn: 'mn',
        ne: 'ne',
        no_NO: 'no_NO',
        or: 'or',
        pl: 'pl',
        pt_BR: 'pt_BR',
        pt_PT: 'pt_PT',
        pa: 'pa',
        rm: 'rm',
        ro_MO: 'ro_MO',
        ro: 'ro',
        ru: 'ru',
        ru_MO: 'ru_MO',
        sa: 'sa',
        sr_SP: 'sr_SP',
        tn: 'tn',
        sd: 'sd',
        si: 'si',
        sk: 'sk',
        sl: 'sl',
        so: 'so',
        sb: 'sb',
        es_AR: 'es_AR',
        es_BO: 'es_BO',
        es_CL: 'es_CL',
        es_CO: 'es_CO',
        es_CR: 'es_CR',
        es_DO: 'es_DO',
        es_EC: 'es_EC',
        es_SV: 'es_SV',
        es_GT: 'es_GT',
        es_HN: 'es_HN',
        es_MX: 'es_MX',
        es_NI: 'es_NI',
        es_PA: 'es_PA',
        es_PY: 'es_PY',
        es_PE: 'es_PE',
        es_PR: 'es_PR',
        es_ES: 'es_ES',
        es_UY: 'es_UY',
        es_VE: 'es_VE',
        sw: 'sw',
        sv_FI: 'sv_FI',
        sv_SE: 'sv_SE',
        tg: 'tg',
        ta: 'ta',
        tt: 'tt',
        te: 'te',
        th: 'th',
        bo: 'bo',
        ts: 'ts',
        tr: 'tr',
        tk: 'tk',
        uk: 'uk',
        ur: 'ur',
        uz_UZ: 'uz_UZ',
        vi: 'vi',
        cy: 'cy',
        xh: 'xh',
        yi: 'yi',
        zu: 'zu'
    }
}