import React, { useEffect, useState } from 'react';
import { FiArrowDown, FiArrowUp, FiBox, FiPlus, FiTrash2 } from 'react-icons/fi';
import { motion } from 'framer-motion';

import InputField from 'components/UI/inputField/InputField';
import AddElement from '../../addElement/AddElement';
import Button from 'components/UI/button/Button';
import Badge from 'components/UI/badge/Badge';

import { blocks } from '../index';

import styling from './Container.module.scss';
import config from '../../../../config';
import etalon from '../../../../etalon';


/**
 * Name of the type.
 * @type {string}
 */
export const CONTAINER_TYPE = 'container';


/**
 * Basic container model.
 * @type {{elements: [], id: string, type: string, customId: string}}
 */
export const containerModel = {
    id: '',
    type: CONTAINER_TYPE,
    customId: '',
    elements: []
};


/**
 * Container element shown in the page or post overview.
 * @param index {number} the index of the element
 * @param elements {array} nested elements
 * @param addElement {function} adds an element
 * @param deleteElement {function} deletes an element
 * @param changePosition {function} changes the position of an element
 * @param openSettings {function} opens the settings for a nested element with the given index
 * @param status {string} the status of the element
 * @returns {*}
 * @constructor
 */
export const ContainerBlock = ({ index, elements, addElement, deleteElement, changePosition, openSettings, isEditor, status }) => {
    const [addElementVisibility, setAddElementVisibility] = useState(false);
    
    /**
     * Adds a nested element.
     * @param type {string} type of the new element
     */
    const add = (type) => {
        addElement(type, index);
        setAddElementVisibility(false);
    };
    
    /**
     * Deletes a nested element.
     * @param nestedIndex {number} index of the nested element that should be deleted
     */
    const remove = (nestedIndex) => {
        deleteElement(index, nestedIndex);
    };
    
    /**
     * Updates the position of a nested element.
     * @param currentIndex {number} index of the element that should change its position
     * @param newIndex {index} index of the desired position
     */
    const updatePosition = (currentIndex, newIndex) => {
        changePosition(index, currentIndex, newIndex);
    };
    
    return (
        <div className={styling.containerWrapper}>
            <div onClick={() => openSettings(index.toString())}>
                <span className={styling.blockType}>CONTAINER</span>
                <Badge size='tiny' type='neutral' hidden={!status}>{status}</Badge>
                <h6>Container</h6>
            </div>
            
            <div className={styling.containerElements}>
                {(elements || []).map((element, i) => {
                    const Element = blocks[element.type || 'fallback'] || blocks.fallback;
                    
                    return (
                        <motion.div className={styling.containerBlock} key={element.id} positionTransition>
                            <div className={styling.element} onClick={() => openSettings(index + '.elements.' + i)}>
                                <Element {...element} />
                            </div>
                            
                            <div className={styling.actions}>
                                <div
                                    className={styling.actionSmall}
                                    onClick={() => updatePosition(i, i - 1)}
                                    hidden={i - 1 < 0}
                                >
                                    <FiArrowUp />
                                </div>
                                
                                <div className={styling.actionSmall} onClick={() => remove(i)}>
                                    <FiTrash2 />
                                </div>
                                
                                <div
                                    className={styling.actionSmall}
                                    onClick={() => updatePosition(i, i + 1)}
                                    hidden={i + 1 >= elements.length}
                                >
                                    <FiArrowDown />
                                </div>
                            </div>
                        </motion.div>
                    );
                })}
                
                <div className={styling.addElementButton} onClick={() => setAddElementVisibility(true)} hidden={isEditor}>
                    <FiPlus />
                </div>
            </div>
            
            <AddElement
                isOpen={addElementVisibility}
                selectHandler={add}
                close={() => setAddElementVisibility(false)}
                isNested={true}
            />
        </div>
    );
};

/**
 * The icon is shown in the list of all elements.
 * @param selectHandler {function} must be invoked when the element is selected
 * @param hidden {boolean} determines if the component should be hidden
 * @returns {*}
 * @constructor
 */
export const ContainerIcon = ({ selectHandler, hidden = false }) => (
    <div className={styling.elementIcon} onClick={() => selectHandler(CONTAINER_TYPE)} hidden={hidden}>
        <div>
            <FiBox />
        </div>
        
        <h6>Container</h6>
        
        <p>contains several elements</p>
    </div>
);

/**
 * Settings for the container element.
 * @param props {object} component props
 * @returns {*}
 * @constructor
 */
export const ContainerSettings = (props) => {
    const [{ id, customId }, setState] = useState(containerModel);
    
    /**
     * Handles input field changes.
     * @param name {string} name of the input field
     * @param value {string} value that was entered into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };
    
    /**
     * Saves the edited settings.
     */
    const save = () => {
        const payload = { ...containerModel, id, customId };
        props.saveSettings(payload);
    };
    
    useEffect(() => {
        const { id, customId, elements } = props;
        setState(prevState => ({ ...prevState, id, customId, elements }));
    }, [props]);
    
    return (
        <>
            <div className={styling.header}>
                <h3>Container</h3>
                <Badge size='tiny' type='info'>ID: {id}</Badge>
            </div>
            
            <div hidden={props.isEditor}>
                <div className={styling.devZone} />
                <div className={styling.row}>
                    <div className={styling.column}>
                        <h6>Custom ID</h6>
                        <InputField
                            name='customId'
                            placeholder='Custom ID'
                            value={customId}
                            onChange={changeHandler}
                            size='small'
                            test={config.regex.maxLength(etalon.maxLength.id)}
                        />
                    </div>
                </div>
            </div>
            
            
            <div className={styling.controls}>
                <Button size='smaller' type='light' onClick={props.closeSettings}>Cancel</Button>
                <Button size='smaller' onClick={save}>Save</Button>
            </div>
        </>
    );
};