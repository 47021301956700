import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';

import Event from '../event/Event';
import Avatar from '../avatar/Avatar';

import utils from 'utils';

import styling from './Content.module.scss';

const Content = (props) => {
    const { currentSite } = useParams();
    
    const { id, index, title, author, updatedBy, avatarId, isPublished, lastUpdate, type, deleteHandler } = props;
    
    return (
        <Event>
            <div className={styling.content} style={{ animationDelay: (index > 24 ? index - 25 : index) * 70 + 'ms' }}>
                <div className={styling.meta}>
                    <Avatar avatarId={avatarId} />
                    
                    <div className={styling.name}>
                        <Link to={'/' + currentSite + '/' + type + '/' + id}>
                            <h6>{title}</h6>
                        </Link>
                        
                        <p>Created by: {author?.firstName || 'Deleted'} {author?.lastName || 'User'}</p>
                    </div>
                </div>
                
                <div className={styling.lastUpdate}>
                    <h6>{updatedBy?.firstName || 'Deleted'} {updatedBy?.lastName || 'User'}</h6>
                    <p>Updated at: {utils.getDate(lastUpdate)}</p>
                </div>
                
                <div className={styling[isPublished ? 'published' : 'unpublished']}>
                    <div />
                    {isPublished ? 'published' : 'unpublished'}
                </div>
                
                <div className={styling.icon} onClick={deleteHandler}><FiTrash2 /></div>
            </div>
        </Event>
    );
};

export default Content;