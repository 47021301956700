import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Confirmation from 'components/UI/confirmation/Confirmation';
import { LoadingSpinner } from 'components/UI/spinner/Spinner';
import Content from 'components/UI/content/Content';
import Button from 'components/UI/button/Button';
import Title from 'components/UI/title/Title';

import { useStore } from 'context';
import client from 'client';
import etalon from 'etalon';

import styling from './Pages.module.scss';

const Pages = ({ history }) => {
    // Store
    const [, , setError] = useStore();
    
    // State
    const [{ pageNumber, pages, pageIdToDelete, initialFetch, fetchedAll, isFetching, isLoading }, setState] = useState({
        pageNumber: 1,
        pages: [],
        pageIdToDelete: '',
        initialFetch: false,
        fetchedAll: false,
        isFetching: true,
        isLoading: true
    });
    
    // Params
    const { currentSite } = useParams();
    
    
    /**
     * Opens the page builder.
     */
    const newPage = () => {
        history.push('/' + currentSite + '/page/');
    };
    
    
    /**
     * Stages the page that should be deleted.
     * This will open the confirmation modal.
     * @param id {string} ID of the page that should be deleted
     */
    const toggleConfirmation = (id = '') => {
        if (typeof id !== 'string') {
            id = '';
        }
        setState(prevState => ({ ...prevState, pageIdToDelete: id || '' }));
    };
    
    
    /**
     * Deletes the page with the staged page ID.
     */
    const deletePage = () => {
        try {
            client('deletePage', null, { pageId: pageIdToDelete, siteId: currentSite });
            
            const tmpPages = [...pages].filter(x => x.id !== pageIdToDelete);
            
            setState(prevState => ({ ...prevState, pages: tmpPages, pageIdToDelete: '' }));
        } catch (error) {
            console.error(error);
            setError(error);
        }
    };
    
    
    /**
     * Loads all pages.
     * @type {(...args: any[]) => any}
     */
    const fetchPages = useCallback(async () => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));
            
            const opts = { siteId: currentSite, page: pageNumber };
            const pages = await client('getAllPages', null, opts);
            
            setState(prevState => ({
                ...prevState,
                pages: [...prevState.pages, ...pages?.data || []],
                isFetching: false,
                pageNumber: prevState.pageNumber + 1,
                fetchedAll: pages?.data?.length < 25 || false,
                initialFetch: true,
                isLoading: false
            }));
            
        } catch (error) {
            console.error(error);
            setError(error);
            setState(prevState => ({ ...prevState, initialFetch: true, isFetching: false, isLoading: false }));
        }
    }, [setError, currentSite, pageNumber]);
    
    
    /**
     * Fetches all pages when the URL changes.
     */
    useEffect(() => {
        if (!initialFetch) {
            fetchPages();
        }
    }, [initialFetch, fetchPages]);
    
    
    return (
        <>
            <Title title='Pages' subtitle='Manage your sites page hierarchy' />
            
            <LoadingSpinner hidden={!isFetching} />
            
            <section className={styling.wrapper} hidden={isFetching}>
                <div className={styling.controls}>
                    <Button size='smaller' onClick={newPage}>New Page</Button>
                </div>
                
                <ul>
                    {pages.map((page, i) => (
                        <Content
                            key={page.id}
                            id={page.id}
                            index={i}
                            title={page?.title?.[etalon.locales.en_US] || 'n/a'}
                            author={page.author}
                            updatedBy={page.updatedBy}
                            avatarId={page.author?.avatar}
                            lastUpdate={page.updatedAt}
                            isPublished={page.isPublished}
                            type='page'
                            deleteHandler={() => toggleConfirmation(page.id)}
                        />
                    ))}
                </ul>
                
                <div className={styling.loadMore}>
                    <Button size='smaller' hidden={fetchedAll} onClick={fetchPages} isLoading={isLoading}>
                        Load More
                    </Button>
                </div>
            </section>
            
            <Confirmation
                open={!!pageIdToDelete}
                title='Delete Page'
                message='Please confirm that you want to delete this page and its data. This is permanent and cannot be undone.'
                cancelHandler={toggleConfirmation}
                confirmHandler={deletePage}
            />
        </>
    );
};

export default Pages;