import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import InputField from 'components/UI/inputField/InputField';
import Checkbox from 'components/UI/checkbox/Checkbox';
import Spinner from 'components/UI/spinner/Spinner';
import Button from 'components/UI/button/Button';
import Modal from 'components/UI/modal/Modal';

import client, { externalRequest } from 'client';
import { useStore } from 'context';
import etalon from 'etalon';
import utils from 'utils';

import logo from 'assets/images/logos/devto.svg';

import styling from './DevTo.module.scss';

const PAGE_SIZE = 250;


/**
 * Allows the user to enter a DEV username whose articles should be imported.
 * @param changeHandler {function} handles input field changes
 * @param username {string} the currently registered DEV username
 * @param cancelHandler {function} closes the modal
 * @param nextStep {function} must be invoked to confirm the username
 * @returns {*}
 * @constructor
 */
const EnterUserName = ({ changeHandler, username, cancelHandler, nextStep }) => (
    <>
        <div className={styling.username}>
            <img src={logo} alt='dev' />
            
            <h3>Import Articles from DEV</h3>
            
            <p>
                Please enter your DEV username below. You will then be able to select the articles you want to
                import in Caasy.
            </p>
            
            <InputField
                name='username'
                placeholder='DEV Username'
                value={username}
                onChange={changeHandler}
                autoFocus
            />
        </div>
        
        <div className={styling.controls}>
            <Button size='smaller' color='light' onClick={cancelHandler}>Cancel</Button>
            <Button size='smaller' disabled={!username} onClick={nextStep}>Next</Button>
        </div>
    </>
);


/**
 * Fetches all published DEV articles for a given username and stages
 * articles selected to import in Caasy.
 * @param username {string} DEV username for the user whose articles should be imported
 * @param prevStep {function} goes one step back in the import flow
 * @param stageSelectedArticles {function} sets an array of selected article IDs in the parent state
 * @returns {*}
 * @constructor
 */
const AvailableArticles = ({ username, prevStep, stageSelectedArticles }) => {
    // Store
    const [, , setError] = useStore();
    
    // State
    const [{ numberOfArticles, fetchedArticles, selectedIds, errorMessage, isFetching }, setState] = useState({
        numberOfArticles: 0,
        fetchedArticles: [],
        selectedIds: {},
        errorMessage: '',
        isFetching: true
    });
    
    
    /**
     * Stores the ID of an article in the state if its corresponding
     * checkbox was checked and removes the ID respectively
     * if the checkbox was unchecked.
     * @param target {object} DOM node of the changed checkbox
     */
    const checkHandler = ({ target }) => {
        const articleId = target.getAttribute('data-id');
        
        setState(prevState => ({
            ...prevState,
            selectedIds: { ...prevState.selectedIds, [articleId]: target.checked }
        }));
    };
    
    
    /**
     * Saves the IDs of the selected articles
     * in the parent state.
     */
    const nextStep = () => {
        const stagedArticleIds = Object
            .entries(selectedIds)
            .map(entry => entry[1] ? entry[0] : null)
            .filter(Boolean);
        
        stageSelectedArticles(stagedArticleIds);
    };
    
    
    /**
     * Fetches all published articles from DEV for a given username.
     * @type {(...args: any[]) => any}
     */
    const fetchAllArticles = useCallback(async () => {
        try {
            let currentPage = 1;
            let allArticles = [];
            
            while (true) {
                const url = 'https://dev.to/api/articles?username=' + username + '&page=' + currentPage + '&per_page=' + PAGE_SIZE;
                const { data = [] } = await externalRequest(url);
                
                setState(prevState => ({
                    ...prevState,
                    numberOfArticles: prevState.numberOfArticles + data.length
                }));
                
                allArticles = [...data, ...allArticles];
                
                currentPage++;
                
                if (data.length < PAGE_SIZE) {
                    break;
                }
            }
            
            setState(prevState => ({
                ...prevState,
                fetchedArticles: allArticles,
                isFetching: false
            }));
            
        } catch (error) {
            console.error(error);
            setError(error);
            setState(prevState => ({ ...prevState, errorMessage: error.message }))
        }
    }, [setError, username]);
    
    
    /**
     * Fetches all articles if the fetchAllArticles
     * function changes.
     */
    useEffect(() => {
        fetchAllArticles();
    }, [fetchAllArticles]);
    
    
    return (
        <div className={styling.articles}>
            <h3>Available Articles</h3>
            
            <p>
                Below you will see all your published articles. Select the articles you want to import in Caasy and
                click next.
            </p>
            
            <div className={styling.spinner} hidden={!isFetching || errorMessage}>
                <Spinner invert />
                <p>fetched {numberOfArticles} articles</p>
            </div>
            
            <ul hidden={isFetching || errorMessage}>
                {fetchedArticles.map(article => (
                    <li key={article.id}>
                        <Checkbox
                            id={article.id}
                            checked={selectedIds[article.id] || false}
                            changeHandler={checkHandler}
                        />
                        
                        <div>
                            <h6>{article.title}</h6>
                            <p>{article.description}</p>
                        </div>
                    </li>
                ))}
            </ul>
            
            <div className={styling.error} hidden={!errorMessage}>
                <h6>Failed to fetch</h6>
                
                <p>
                    Failed to fetch articles from DEV with the following error: {errorMessage}
                </p>
            </div>
            
            <div className={styling.controls}>
                <Button size='smaller' color='light' onClick={prevStep}>Cancel</Button>
                <Button size='smaller' onClick={nextStep} disabled={errorMessage}>Import</Button>
            </div>
        </div>
    );
};


/**
 * Imports the DEV articles with the given IDs to Caasy.
 * @param articleIdsToImport {array} an array of article IDs from DEV that should be imported in Caasy
 * @param close {function} closes the modal
 * @returns {*}
 * @constructor
 */
const ImportArticles = ({ articleIdsToImport, close }) => {
    // Store
    const [, , setError] = useStore();
    
    // State
    const [{ importedArticlesCount, isImporting, errorMessage }, setState] = useState({
        importedArticlesCount: 0,
        isImporting: true,
        errorMessage: ''
    });
    
    // Params
    const { currentSite } = useParams();
    
    
    /**
     * Iterates through all article IDs and fetches each
     * article from DEV and imports it in Caasy.
     * @type {(...args: any[]) => any}
     */
    const importArticles = useCallback(async () => {
        try {
            for (let articleId of articleIdsToImport) {
                const url = 'https://dev.to/api/articles/' + articleId;
                const { data = {} } = await externalRequest(url);
                
                const blogPost = {
                    siteId: currentSite,
                    title: {
                        [etalon.locales.en_US]: data.title
                    },
                    description: {
                        [etalon.locales.en_US]: data.description
                    },
                    elements: [
                        {
                            customId: '',
                            id: Date.now().toString() + '-' + utils.random(5),
                            title: {
                                [etalon.locales.en_US]: data.title
                            },
                            subtitle: {
                                [etalon.locales.en_US]: ''
                            },
                            type: 'headline'
                        },
                        {
                            customId: '',
                            id: Date.now().toString() + '-' + utils.random(5),
                            text: {
                                [etalon.locales.en_US]: data.body_markdown
                            },
                            type: 'markdown'
                        }
                    ]
                };
                
                await client('createPost', blogPost);
                
                setState(prevState => ({
                    ...prevState,
                    importedArticlesCount: prevState.importedArticlesCount + 1
                }));
            }
            
            setState(prevState => ({ ...prevState, isImporting: false }));
            
        } catch (error) {
            console.error(error);
            setError(error);
            setState(prevState => ({ ...prevState, errorMessage: error.message }));
        }
    }, [setError, articleIdsToImport, currentSite]);
    
    
    /**
     * Fetches and imports articles when the importArticles
     * function changes.
     */
    useEffect(() => {
        importArticles();
    }, [importArticles]);
    
    return (
        <div>
            <h3>Importing Articles</h3>
            
            <div className={styling.spinner} hidden={!isImporting || errorMessage}>
                <Spinner invert />
                <p>imported {importedArticlesCount} of {articleIdsToImport.length} articles from DEV</p>
            </div>
            
            <div className={styling.success} hidden={isImporting || errorMessage}>
                <h6>Import finished successfully</h6>
                
                <p>All selected articles were imported from DEV.</p>
            </div>
            
            <div className={styling.error} hidden={!errorMessage}>
                <h6>Failed to import articles</h6>
                
                <p>Failed to import articles with error: {errorMessage}</p>
            </div>
            
            <div className={styling.controls}>
                <Button size='smaller' onClick={close} disabled={isImporting}>Finish</Button>
            </div>
        </div>
    );
};


/**
 * Guides the user through the process of
 * importing articles from DEV.
 * @param isVisible {boolean} determines if the modal is visible
 * @param close {function} closes the modal
 * @returns {*}
 * @constructor
 */
const DevTo = ({ isVisible = true, close }) => {
    const [{ step, username, stagedArticleIds }, setState] = useState({
        step: 1,
        username: '',
        stagedArticleIds: []
    });
    
    
    /**
     * Handles input field changes.
     * @param name {string} name of the input field that was changed
     * @param value {string} text that was entered into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };
    
    
    /**
     * Returns to the previous step.
     */
    const prevStep = () => {
        setState(prevState => ({ ...prevState, step: prevState.step - 1, stagedArticleIds: [] }));
    };
    
    
    /**
     * Goes to the next step.
     */
    const nextStep = () => {
        setState(prevState => ({ ...prevState, step: prevState.step + 1 }));
    };
    
    
    /**
     * Sets an array with staged article IDs in the state and goes to the import step.
     * @param stagedArticleIds {array} an array of article IDs from DEV that should be imported
     */
    const stageSelectedArticles = (stagedArticleIds) => {
        setState(prevState => ({ ...prevState, step: 3, stagedArticleIds }));
    };
    
    
    // Allows the user to enter their DEV username
    let currentStep = (
        <EnterUserName
            username={username}
            changeHandler={changeHandler}
            cancelHandler={close}
            nextStep={nextStep}
        />
    );
    
    
    // Allows the user to select the articles to import
    if (step === 2) {
        currentStep = (
            <AvailableArticles
                username={username}
                prevStep={prevStep}
                stageSelectedArticles={stageSelectedArticles}
            />
        );
    }
    
    
    // Imports the selected articles
    if (step === 3) {
        currentStep = (
            <ImportArticles
                articleIdsToImport={stagedArticleIds}
                close={close}
            />
        );
    }
    
    
    return (
        <Modal open={isVisible}>
            {currentStep}
        </Modal>
    );
};

export default DevTo;