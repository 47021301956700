import React from 'react';

import person from 'assets/images/persons/2.png';

import styling from './Banner.module.scss';

const AvailableSoon = ({ children }) => (
    <article className={styling.availableSoon}>
        <div>
            <h3>Available soon</h3>
            <p>{children}</p>
        </div>
        
        <img src={person} alt='person' />
    </article>
);

export default AvailableSoon;