import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { LoadingSpinner } from 'components/UI/spinner/Spinner';
import { WideButton } from 'components/UI/button/Button';
import Menu from 'components/layout/menu/Menu';

import client from 'client';

import styling from './EmailConfirmation.module.scss';

const EmailConfirmation = ({ location }) => {
    const [{ isLoading, success }, setState] = useState({
        isLoading: true,
        success: false
    });
    
    
    /**
     * Confirms the email address.
     * @returns {Promise<void>}
     */
    const confirmEmailAddress = useCallback(async () => {
        try {
            const confirmationCode = new URLSearchParams(location.search).get('code') || '';
            
            await client('confirmEmail', { confirmationCode });
            
            setState(prevState => ({ ...prevState, isLoading: false, success: true }));
            
        } catch (error) {
            console.error(error);
            setState(prevState => ({ ...prevState, isLoading: false }));
        }
    }, [location.search]);
    
    
    /**
     * Confirms the email address when the component has rendered.
     */
    useEffect(() => {
        confirmEmailAddress();
    }, [confirmEmailAddress]);
    
    
    // Success message
    const successMessage = (
        <>
            <h3>Email address confirmed!</h3>
            <p>
                Thank you for confirming your email address. You are no ready to go. Please sign in into your
                account to get started.
            </p>
            <WideButton>
                <Link to='/auth/signin'>Go to sign in</Link>
            </WideButton>
        </>
    );
    
    
    // Failure message
    const failureMessage = (
        <>
            <h3>Confirmation failed!</h3>
            <p>
                Unfortunately, we were not able to confirm your email address. You have either entered an invalid
                url, or the email address has already been confirmed.
            </p>
            <h3>:-(</h3>
        </>
    );
    
    
    // Loading spinner
    const spinner = (
        <div className={styling.spinner}>
            <LoadingSpinner />
        </div>
    );
    
    
    // Determines the message
    const message = success ? successMessage : failureMessage;
    
    return (
        <>
            <Menu />
            
            <main>
                <div className={styling.message}>
                    {isLoading ? spinner : message}
                </div>
            </main>
        </>
    );
};

export default EmailConfirmation;