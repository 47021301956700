import { TEXT_TYPE, TextBlock, TextIcon, textModel, TextSettings } from './text/Text';
import { IMAGE_TYPE, ImageBlock, ImageIcon, imageModel, ImageSettings } from './image/Image';
import { HEADLINE_TYPE, HeadlineBlock, HeadlineIcon, headlineModel, HeadlineSettings } from './headline/Headline';
import { RICH_TEXT_TYPE, RichTextBlock, RichTextIcon, richTextModel, RichTextSettings } from './richText/RichText';
import { CONTAINER_TYPE, ContainerBlock, ContainerIcon, containerModel, ContainerSettings } from './container/Container';
import { MARKDOWN_TYPE, MarkdownBlock, MarkdownIcon, markdownModel, MarkdownSettings } from './markdown/Markdown';
import { YOUTUBE_TYPE, YouTubeBlock, YouTubeIcon, youtubeModel, YouTubeSettings } from './youtube/Youtube';
import { CARD_TYPE, CardBlock, CardIcon, cardModel, CardSettings } from './card/Card';
import { FILE_TYPE, FileBlock, FileIcon, fileModel, FileSettings } from './file/File';
import { HTML_TYPE, HtmlBlock, HtmlIcon, htmlModel, HtmlSettings } from './html/Html';
import { HERO_TYPE, HeroBlock, HeroIcon, heroModel, HeroSettings } from './hero/Hero';

export const blocks = {
    [TEXT_TYPE]: TextBlock,
    [IMAGE_TYPE]: ImageBlock,
    [HEADLINE_TYPE]: HeadlineBlock,
    [RICH_TEXT_TYPE]: RichTextBlock,
    [CONTAINER_TYPE]: ContainerBlock,
    [MARKDOWN_TYPE]: MarkdownBlock,
    [YOUTUBE_TYPE]: YouTubeBlock,
    [CARD_TYPE]: CardBlock,
    [FILE_TYPE]: FileBlock,
    [HTML_TYPE]: HtmlBlock,
    [HERO_TYPE]: HeroBlock,
    fallback: () => null
};

export const settings = {
    [TEXT_TYPE]: TextSettings,
    [IMAGE_TYPE]: ImageSettings,
    [HEADLINE_TYPE]: HeadlineSettings,
    [RICH_TEXT_TYPE]: RichTextSettings,
    [CONTAINER_TYPE]: ContainerSettings,
    [MARKDOWN_TYPE]: MarkdownSettings,
    [YOUTUBE_TYPE]: YouTubeSettings,
    [CARD_TYPE]: CardSettings,
    [FILE_TYPE]: FileSettings,
    [HTML_TYPE]: HtmlSettings,
    [HERO_TYPE]: HeroSettings,
    fallback: () => null
};

export const models = {
    [TEXT_TYPE]: textModel,
    [IMAGE_TYPE]: imageModel,
    [HEADLINE_TYPE]: headlineModel,
    [RICH_TEXT_TYPE]: richTextModel,
    [CONTAINER_TYPE]: containerModel,
    [MARKDOWN_TYPE]: markdownModel,
    [YOUTUBE_TYPE]: youtubeModel,
    [FILE_TYPE]: fileModel,
    [CARD_TYPE]: cardModel,
    [HTML_TYPE]: htmlModel,
    [HERO_TYPE]: heroModel
};

export const typeNames = {
    [TEXT_TYPE]: TEXT_TYPE,
    [IMAGE_TYPE]: IMAGE_TYPE,
    [HEADLINE_TYPE]: HEADLINE_TYPE,
    [RICH_TEXT_TYPE]: RICH_TEXT_TYPE,
    [CONTAINER_TYPE]: CONTAINER_TYPE,
    [MARKDOWN_TYPE]: MARKDOWN_TYPE,
    [YOUTUBE_TYPE]: YOUTUBE_TYPE,
    [CARD_TYPE]: CARD_TYPE,
    [FILE_TYPE]: FILE_TYPE,
    [HTML_TYPE]: HTML_TYPE,
    [HERO_TYPE]: HERO_TYPE
};

export const icons = [
    {
        category: 'Basic',
        elementsInCategories: [TextIcon, RichTextIcon, HeadlineIcon, CardIcon, HeroIcon]
    },
    {
        category: 'Media',
        elementsInCategories: [ImageIcon, YouTubeIcon, FileIcon]
    },
    {
        category: 'Layout',
        elementsInCategories: [ContainerIcon]
    },
    {
        category: 'Developers',
        elementsInCategories: [MarkdownIcon, HtmlIcon]
    }
];